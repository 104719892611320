import { CommonSvgShapes } from "../../../types"
import { calculateAngleBetweenLines } from "../../../utils/imageMeasurements"


export default function MeasurementLayerAngleSvgs(
    { angles, strokeWidth }: {
        angles: CommonSvgShapes[],
        strokeWidth: string | number,
    }
) {
    const constructAngleSvgPolyline = (start: [number, number], vertex: [number, number], end: [number, number]) => {
        let points = start[0] + "," + start[1] + " "
        if (vertex) {
            points += vertex[0] + "," + vertex[1] + " "
        }
        if (end) {
            points += end[0] + "," + end[1]
        }
        return points
    }

    const calculateThirdPoint = (point1: [number, number], point2: [number, number], length: number) => {
        const distance = Math.sqrt(Math.pow(point1[0] - point2[0], 2) + Math.pow(point1[1] - point2[1], 2))
        const ratio = length / distance
        const x3 = ((1 - ratio) * point1[0]) + (ratio * point2[0])
        const y3 = ((1 - ratio) * point1[1]) + (ratio * point2[1])
        return [x3, y3] as [number, number]
    }

    const generateArcPath = (start: [number, number], vertex: [number, number], end: [number, number]) => {
        // Also makes the label x/y. I could separate. TODO
        const calculateLength = (point1: [number, number], point2: [number, number]) => {
            return Math.sqrt(Math.pow(point1[0] - point2[0], 2) + Math.pow(point1[1] - point2[1], 2))
        }
        const lineLength1 = calculateLength(start, vertex)
        const lineLength2 = calculateLength(end, vertex)
        let arcStart: [number, number]
        let arcEnd: [number, number]
        let arcRadius: number
        if (lineLength1 < lineLength2) {
            arcStart = start
            arcEnd = calculateThirdPoint(vertex, end, lineLength1)
            arcRadius = lineLength1
        }
        else {
            arcStart = calculateThirdPoint(vertex, start, lineLength2)
            arcEnd = end
            arcRadius = lineLength2
        }
        const angle = calculateAngleBetweenLines({ x: start[0], y: start[1] }, { x: vertex[0], y: vertex[1] }, { x: end[0], y: end[1] })
        // for reference on these magic numbers: https://svg-tutorial.com/svg/arc/
        const smoothFlag = 1
        const rotation = 0
        const largeArcFlag = angle >= 180 ? 1 : 0
        const arcPath = `
            M ${arcStart[0]} ${arcStart[1]}
            A ${arcRadius} ${arcRadius} ${rotation} ${largeArcFlag} ${smoothFlag} ${arcEnd[0]} ${arcEnd[1]}
          `;
        return arcPath
    }

    return <>
        {
            angles.map(e => (
                e.coordinates.length === 3 &&
                <path key={e.id}
                    d={generateArcPath(e.coordinates[0], e.coordinates[1], e.coordinates[2])}
                    fill="none"
                    stroke={e.color}
                    strokeDasharray="5,5"
                    strokeWidth={strokeWidth}></path>))
        }
        {
            angles.map(e => (
                <polyline key={e.id}
                    points={constructAngleSvgPolyline(e.coordinates[0], e.coordinates[1], e.coordinates[2])}
                    fill="none"
                    stroke={e.color}
                    strokeWidth={strokeWidth}></polyline>
            ))
        }
    </>
}
