import FilterListIcon from '@mui/icons-material/FilterList';
import { Box, Button, Checkbox, List, ListItem, ListItemIcon, ListItemText, Menu, TextField } from "@mui/material";
import { useState } from "react";
import { formatIsoDate } from "../../utils";

export interface ScanFilterUniqueScans {
    scan_id: number;
    sn: string;
    scan_date: Date;
}

export default function ScanFilterAutocomplete(
    {
        filterScanIds,
        uniqueScans,
        setFilterScanIds
    }: {
        filterScanIds: number[] | null,
        uniqueScans: ScanFilterUniqueScans[],
        setFilterScanIds: React.Dispatch<React.SetStateAction<number[] | null>>
    }) {


    const [anchorEl, setAnchorEl] = useState(null);
    const [filter, setFilter] = useState("");

    const formatText = (e: {
        scan_id: number;
        sn: string;
        scan_date: Date;
    }) => `${e.scan_id}: ${e.sn}, ${formatIsoDate(e.scan_date)}`

    const handleOpen = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleToggle = (scan_id: number) => {
        if (filterScanIds === null) {
            const allOthers = uniqueScans.filter((e) => e.scan_id !== scan_id)
            setFilterScanIds(allOthers.map((e) => e.scan_id));
        } else {
            if (filterScanIds.includes(scan_id)) {
                setFilterScanIds(filterScanIds.filter((e) => e !== scan_id));
            } else {
                setFilterScanIds([...filterScanIds, scan_id]);
            }
        }
    };

    const handleSelectAll = () => {
        if (filterScanIds === null) {
            setFilterScanIds([]);
        }
        else if (filterScanIds.length === uniqueScans.length) {
            setFilterScanIds([]);
        }
        else {
            setFilterScanIds(null);
        };
    }

    const numberSelected = filterScanIds === null ? uniqueScans.length : filterScanIds.length;

    return (
        <Box>
            <Button onClick={handleOpen} variant='outlined' size="small" fullWidth>
                <FilterListIcon fontSize="small" />Filter scans (selected {numberSelected} of {uniqueScans.length})
            </Button>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                <Box sx={{ p: 1, width: 300, height: 400 }}>
                    <TextField
                        fullWidth
                        label="Search"
                        variant="outlined"
                        size="small"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                    />
                    <ListItem dense onClick={handleSelectAll}>
                        <ListItemIcon>
                            <Checkbox
                                sx={{ p: 0, m: 0 }}
                                checked={filterScanIds === null}
                                indeterminate={filterScanIds !== null && filterScanIds.length > 0 && filterScanIds.length < uniqueScans.length}
                            />
                        </ListItemIcon>
                        <ListItemText primary="Select All" />
                    </ListItem>
                    <List dense>
                        {uniqueScans.filter((e) => e.sn.toLowerCase().concat(e.scan_id.toString()).includes(filter.toLowerCase()))
                            .map((e) => (
                                <ListItem key={e.scan_id} onClick={() => handleToggle(e.scan_id)}>
                                    <ListItemIcon>
                                        <Checkbox sx={{ p: 0, m: 0 }} checked={filterScanIds === null || filterScanIds.includes(e.scan_id)} />
                                    </ListItemIcon>
                                    <ListItemText primary={formatText(e)} />
                                </ListItem>
                            ))}
                    </List>
                </Box>
            </Menu>
        </Box>
    );
};
