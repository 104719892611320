import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Box, Collapse, Fade, IconButton, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useEffect, useState } from 'react';
import { RequestInfo, ScanList } from "../../types";
import { formatIsoDate, formatMetadata, newTabLink } from '../../utils';
import { childRowSx, parentHeaderSx, parentRowSx, subTableWrapperRowSx, tableCellSx } from '../../utils/tableFormats';


export default function ScansPage(
  { scanList, isDemoMode }: { scanList: ScanList[], isDemoMode: boolean }
) {
  const [fadeIn, setFadeIn] = useState(false)
  const [sortedColumn, setSortedColumn] = useState("scan_id");
  const [sortDirection, setSortDirection] = useState<'asc' | "desc">('desc');
  const [filterText, setFilterText] = useState('');
  const [openRequest, setOpenRequest] = useState(0);

  const handleSortClick = (key: string) => {
    if (sortedColumn === key) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
    }
    else {
      setSortDirection('asc')
    }
    setSortedColumn(key)
  }
  useEffect(() => {
    setFadeIn(true)
    return () => { setFadeIn(false) }
  }, [])

  const filteredScanList = scanList.filter((e) => {
    return JSON.stringify(e).toLowerCase().includes(filterText.toLowerCase()) || filterText === ''
  })

  const uniqueRequestListWithCount = filteredScanList.reduce((acc: RequestInfo[], obj: ScanList) => {
    const existingIndex = acc.findIndex((o) => o.request_id === obj.request_id);
    if (existingIndex !== -1) {
      acc[existingIndex].scan_count++;
      if (obj.post_processing_completed > acc[existingIndex].post_processing_completed) {
        acc[existingIndex].post_processing_completed = obj.post_processing_completed;
      }
    } else {
      acc.push({
        request_id: obj.request_id,
        scan_count: 1,
        request_name: obj.request_name,
        cell_model_name: obj.cell_model_name,
        cell_model_vendor: obj.cell_model_vendor,
        scanner_vendor: obj.scanner_vendor,
        post_processing_completed: obj.post_processing_completed
      });
    }
    return acc;
  }, [])

  const tableData = [...filteredScanList].sort((a: any, b: any) => {
    if (sortedColumn === null) return 0;
    if (a[sortedColumn] < b[sortedColumn]) {
      return sortDirection === 'asc' ? -1 : 1;
    }
    if (a[sortedColumn] > b[sortedColumn]) {
      return sortDirection === 'asc' ? 1 : -1;
    }
    return 0;
  })

  const openTableData = tableData.filter((e) => e.request_id === openRequest)
  const openTableContainsContextData = openTableData.some((e) => e.metadata !== null)

  const openTableColumns = openTableContainsContextData ? [
    { name: "Serial Number", key: "cell_sn" },
    { name: "Scan ID", key: "scan_id" },
    { name: "Scan Date", key: "post_processing_completed" },
    { name: "Context Data", key: "metadata" }
  ] : [
    { name: "Serial Number", key: "cell_sn" },
    { name: "Scan ID", key: "scan_id" },
    { name: "Scan Date", key: "post_processing_completed" }
  ]


  return (
    <Box sx={{ maxWidth: 1300, margin: "auto" }}>
      <Fade in={fadeIn}>
        <Grid container>
          <Grid xs={8}>
            <Typography variant="h5" sx={{ flexGrow: 1, pt: "14pt", pb: "14pt" }}>
              List of Scans:
            </Typography>
          </Grid>
          <Grid xs={4} sx={{ justifyContent: 'flex-end' }} >
            <TextField
              id="outlined-basic"
              label="Quick Filter"
              variant="outlined"
              fullWidth
              value={filterText}
              margin="dense"
              onChange={(e) => setFilterText(e.target.value)}
            />
          </Grid>
        </Grid>
      </Fade>
      <Fade in={fadeIn}>
        <Grid >
          <TableContainer >
            <Table size="small" sx={{ minWidth: 650 }} >
              <TableHead>
                <TableRow
                  sx={parentHeaderSx}
                >
                  <TableCell sx={tableCellSx}></TableCell>
                  <TableCell sx={tableCellSx}>Batch Name</TableCell >
                  <TableCell sx={tableCellSx}>Scan Count</TableCell>
                  <TableCell sx={tableCellSx}>Cell Model</TableCell>
                  <TableCell sx={tableCellSx}>Producer</TableCell>
                  <TableCell sx={tableCellSx}>Last Scan Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {uniqueRequestListWithCount.map(
                  (e) => (
                    <React.Fragment key={e.request_id}>
                      <TableRow sx={parentRowSx}>
                        <TableCell sx={tableCellSx}>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => {
                              openRequest === e.request_id ? setOpenRequest(0) :
                                setOpenRequest(e.request_id)
                            }}
                          >
                            {openRequest === e.request_id ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                          </IconButton>
                        </TableCell>
                        <TableCell sx={tableCellSx}>{e.request_name}</TableCell >
                        <TableCell sx={tableCellSx}>{e.scan_count}</TableCell>
                        <TableCell sx={tableCellSx}>{e.cell_model_name}</TableCell>
                        <TableCell sx={tableCellSx}>{e.cell_model_vendor}</TableCell>
                        <TableCell sx={tableCellSx}>
                          {formatIsoDate(e.post_processing_completed)}
                        </TableCell>
                      </TableRow>
                      <TableRow >
                        <TableCell sx={subTableWrapperRowSx} colSpan={6}>
                          <Collapse in={openRequest === e.request_id} timeout="auto" unmountOnExit sx={{ justifyContent: 'center', justifyItems: 'center' }}>
                            <Grid container sx={{ pl: 5, pt: 1, pb: 1, pr: 5, justifyContent: 'center', justifyItems: 'center' }}>
                              <Table size="small" sx={{ minWidth: 550, maxWidth: openTableContainsContextData ? 1000 : 700, }} >
                                <TableHead>
                                  <TableRow
                                    sx={parentRowSx}
                                  >
                                    {
                                      openTableColumns.map((column) => (
                                        <TableCell key={column.key} sx={tableCellSx}>
                                          <TableSortLabel
                                            active={sortedColumn === column.key}
                                            direction={sortedColumn === column.key ? sortDirection : 'asc'}
                                            onClick={() => handleSortClick(column.key)}
                                          >
                                            {column.name}
                                          </TableSortLabel>
                                        </TableCell>
                                      ))
                                    }
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {openTableData
                                    .map((r) => (
                                      <TableRow
                                        key={r.scan_id}
                                        sx={childRowSx}
                                      // TODO Color here
                                      >
                                        <TableCell sx={tableCellSx}>
                                          {r.cell_sn}
                                        </TableCell>
                                        <TableCell sx={tableCellSx}>
                                          <Link href={newTabLink(r.scan_id, isDemoMode)} target="_blank">
                                            {r.scan_id}
                                          </Link>
                                        </TableCell>
                                        <TableCell sx={tableCellSx}>
                                          {formatIsoDate(r.post_processing_completed)}
                                        </TableCell>
                                        {openTableContainsContextData &&
                                          <TableCell sx={tableCellSx}>
                                            {formatMetadata(r.metadata)}
                                          </TableCell>
                                        }
                                      </TableRow>
                                    ))}
                                </TableBody>
                              </Table>
                            </Grid>
                          </Collapse>
                        </TableCell>
                      </TableRow >
                    </React.Fragment>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Fade>
    </Box >
  );
}
