import { AutomatedInspectionMetrics, StatsDisplayNames } from "."
import {
    AnnotationShapeList, AnnotationShapeOptions, ManualMeasurementResults,
    PointGeometry, RawCathodeTraceResults, RawElectrodeOverhangTraceResults,
    RawGeneralInspectionResults
} from "../../types"
import { colorAnnotation, overlayColorScheme } from "../colorScheme"
import { getShapesForAnodesAndCathodes } from "./cathodeAnodeInspectionShapes"
import { getCanWallThicknessShapes, getMeanCrimpGrooveGapShapes } from "./specialInspectionShapes"

const determineShapeType = (metricName: AutomatedInspectionMetrics) => {
    switch (metricName) {
        case AutomatedInspectionMetrics.ANODE_TAB_CURVATURE:
            return AnnotationShapeOptions.POLYLINE
        case AutomatedInspectionMetrics.CORE_AREA:
        case AutomatedInspectionMetrics.CORE_ANODE_GAP_WIDTH:
            return AnnotationShapeOptions.POLYGON
        case AutomatedInspectionMetrics.CAN_WALL_THICKNESS_MEAN:
        case AutomatedInspectionMetrics.CAN_INNER_DIAMETER_MEAN:
        case AutomatedInspectionMetrics.CAN_OUTER_DIAMETER_MEAN:
            return AnnotationShapeOptions.POINTS
        case AutomatedInspectionMetrics.CORE_CIRCULARITY_DMIN_DMAX:
        case AutomatedInspectionMetrics.CAN_CIRCULARITY_DMIN_DMAX:
            return AnnotationShapeOptions.ELLIPSE_AND_LINES
        case AutomatedInspectionMetrics.CORE_CIRCULARITY_MCC:
            return AnnotationShapeOptions.CIRCLE_AND_LINE
        case AutomatedInspectionMetrics.CORE_CONCENTRICITY:
        case AutomatedInspectionMetrics.LEFT_CRIMP_HEIGHT:
        case AutomatedInspectionMetrics.RIGHT_CRIMP_HEIGHT:
        case AutomatedInspectionMetrics.MAX_CRIMP_GROOVE_GAP:
        case AutomatedInspectionMetrics.MIN_CRIMP_GROOVE_GAP:
            return AnnotationShapeOptions.LINE
        case AutomatedInspectionMetrics.CATHODE_TO_END_ANODE_TAB:
        case AutomatedInspectionMetrics.MIDDLE_CATHODE_TAB_TO_MIDDLE_ANODE_TAB:
        case AutomatedInspectionMetrics.CATHODE_START_TO_MIDDLE_CATHODE_TAB:
            return AnnotationShapeOptions.ANGLE
        case AutomatedInspectionMetrics.CORE_EFFECTIVE_DIAMETER:
            return AnnotationShapeOptions.NONE
        case AutomatedInspectionMetrics.CAN_MAX_DENTING:
            return AnnotationShapeOptions.ELLIPSE_AND_LINES
        default:
            return AnnotationShapeOptions.NONE // TODO: check this default assumption...
    }
}


export const getListOfShapes = (
    manualMeasurements: ManualMeasurementResults[],
    highlightedManualMeasurement: number,
    highlightedAutomaticInspection: { metric: AutomatedInspectionMetrics, stat?: StatsDisplayNames } | null,
    visible_slice_id: number,
    visibleInspectionNames: string[],
    allRawAutomatedInspectionResults: {
        general: RawGeneralInspectionResults[],
        cathode: RawCathodeTraceResults[],
        electrode: RawElectrodeOverhangTraceResults[]
    }
) => {
    let combined = [] as AnnotationShapeList[]

    const manual = manualMeasurements.filter((e) => e.display)
        .map((e) => {
            return {
                coordinates: e.coordinates.map((e: PointGeometry) => [e.x, e.y]), // TODO: could drop point geometry type
                shapeType: e.shapeType as string as AnnotationShapeOptions, // TODO: lazy but quick typing. slim opportunity for error here.
                color: e.id === highlightedManualMeasurement ?
                    overlayColorScheme.red : overlayColorScheme.red.concat("98")
            } as AnnotationShapeList
        })
    combined.push(...manual)

    const automatedAtSlice = allRawAutomatedInspectionResults.general.filter((e) => e.slice_id === visible_slice_id)

    const automatedGeneral = automatedAtSlice
        .filter((e) => visibleInspectionNames.includes(e.metric_internal_name))
        .map((e) => {
            const metricName = e.metric_internal_name
            const isHighlighted = highlightedAutomaticInspection?.metric === metricName
            return {
                coordinates: e.shape_points,
                shapeType: determineShapeType(metricName),
                color: colorAnnotation(metricName, isHighlighted)
            } as AnnotationShapeList
        })
    combined.push(...automatedGeneral)

    const anodeAndCathodeShapes = getShapesForAnodesAndCathodes(
        visibleInspectionNames,
        highlightedAutomaticInspection,
        allRawAutomatedInspectionResults.cathode,
        allRawAutomatedInspectionResults.electrode,
        visible_slice_id
    )
    if (anodeAndCathodeShapes) combined.push(...anodeAndCathodeShapes)

    const canWallThicknessShapes = getCanWallThicknessShapes(
        automatedAtSlice,
        visibleInspectionNames,
        highlightedAutomaticInspection?.metric === AutomatedInspectionMetrics.CAN_WALL_THICKNESS_MEAN
    )
    if (canWallThicknessShapes) combined.push(...canWallThicknessShapes)

    const meanCrimpGrooveGapShapes = getMeanCrimpGrooveGapShapes(
        automatedAtSlice,
        visibleInspectionNames,
        highlightedAutomaticInspection?.metric === AutomatedInspectionMetrics.MEAN_CRIMP_GROOVE_GAP
    )
    if (meanCrimpGrooveGapShapes) combined.push(...meanCrimpGrooveGapShapes)

    return combined.map((e: AnnotationShapeList, index) => ({
        ...e,
        id: index // Potential GOTCHA: id's on the display side are all indexes, presently.
    }))
}
