import * as PlotL from "@observablehq/plot";
import * as d3 from "d3";
import { useEffect, useRef } from "react";
import { colorScheme } from "../../utils/colorScheme";
import { AutomatedInspectionMetrics } from "../../utils/inspection";
import { ChartSeriesOptions, formatMetricName, InspectionChartData, plotBackground, tooltipBackground, tooltipTextCrossCompare, transformData, XAxisOptions } from "../../utils/inspection/dashboard";
import './plotStyles.css';

export default function CrossComparePlot(
    {
        data,
        plotDims,
        checkboxes
    }: {
        data: InspectionChartData[];
        plotDims: { width: number; height: number };
        checkboxes: { mean: boolean; median: boolean; min: boolean; max: boolean };
    }) {
    const containerRef = useRef<HTMLDivElement>(null);

    const marginLeft = 80
    const marginBottom = 80


    const createComparisonPlot = (
        xMetric: string,
        yMetric: string,
        data: InspectionChartData[],
        categoryX: string,
        categoryY: string
    ) => {
        const transformedData = transformData(data, XAxisOptions.CROSS_COMPARE);
        const xValues = transformedData.filter(d => d.metric === xMetric && d.value !== null);
        const yValues = transformedData.filter(d => d.metric === yMetric && d.value !== null);
        const mergedData = xValues.map(x => {
            const yMatch = yValues.find(y => y.sn === x.sn);
            return yMatch ? {
                x: x.value,
                y: yMatch.value,
                series: x.series,
                sn: x.sn,
                scanId: x.scanId,
                batch: x.batch,
                date: x.date
            } : null;
        })
            .filter(Boolean)
            .filter((d: any) => d.series !== ChartSeriesOptions.MEAN || checkboxes.mean)
            .filter((d: any) => d.series !== ChartSeriesOptions.MEDIAN || checkboxes.median)
            .filter((d: any) => d.series !== ChartSeriesOptions.MIN || checkboxes.min)
            .filter((d: any) => d.series !== ChartSeriesOptions.MAX || checkboxes.max)


        return PlotL.plot({
            width: plotDims.width,
            height: plotDims.height,
            marginLeft: marginLeft,
            marginBottom: marginBottom,
            className: "plot-chart-class",
            inset: 10,
            x: {
                label: formatMetricName(categoryX as AutomatedInspectionMetrics),
                labelOffset: 40
            },
            y: {
                label: formatMetricName(categoryY as AutomatedInspectionMetrics),
                labelOffset: 30
            },
            color: {
                domain: [ChartSeriesOptions.MIN, ChartSeriesOptions.MAX, ChartSeriesOptions.MEAN, ChartSeriesOptions.MEDIAN],
                range: [colorScheme.min, colorScheme.max, colorScheme.average, colorScheme.median]
            },
            marks: [
                PlotL.frame({ fill: plotBackground }),
                PlotL.dot(mergedData, { x: "x", y: "y", fill: "series" }),
                PlotL.tip(
                    mergedData.filter(d => d?.series !== null),
                    PlotL.pointer({
                        x: "x",
                        y: "y",
                        title: d => tooltipTextCrossCompare(d),
                        fill: tooltipBackground,
                        fontSize: 12,
                        maxRadius: 15, // how close to the point the mouse has to be to trigger the tooltip
                    })
                )
            ],
            grid: true
        });
    };

    const generateComparisonPlot = (data: InspectionChartData[]) => {
        const groupedData = d3.group(data, d => d.metric_internal_name);
        const plotElements: HTMLDivElement[] = [];

        const metrics = Array.from(groupedData.keys());
        for (let i = 0; i < metrics.length; i++) {
            for (let j = i + 1; j < metrics.length; j++) {
                const plot = createComparisonPlot(metrics[i], metrics[j], data, metrics[i], metrics[j]);
                const plotDiv = document.createElement("div");
                plotDiv.style.display = "inline-block";
                plotDiv.appendChild(plot);
                plotElements.push(plotDiv);
            }
        }
        return plotElements;
    };

    useEffect(() => {
        if (!containerRef.current) return;
        containerRef.current.innerHTML = "";
        const plotElements = generateComparisonPlot(data);
        plotElements.forEach(element => containerRef.current?.appendChild(element));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return <div ref={containerRef} />;
};
