import useUrlState from "@ahooksjs/use-url-state";
import { Button, Grid } from "@mui/material";
import throttle from 'lodash/throttle';
import { useCallback, useEffect, useState } from "react";
import { ScanAxes, ScanList, ScanMetadata, SliceOrientation, SlicePositionsCyl, SlicePositionsXyz, SliceRow } from "../../types";
import { getValidSlicePosition } from "../../utils";
import OneAxisSliceView from "./OneAxisSliceView";
import ScanMetaData from "./ScanMetaData";
export default function SliceDisplayManager(
    {
        scanData,
        sliceList,
        scanAxes,
        isDemoMode,
        scanMetadata,
        toggleScanContext
    }: {
        scanData: ScanList,
        sliceList: SliceRow[],
        scanAxes: ScanAxes,
        isDemoMode: boolean
        scanMetadata: ScanMetadata[]
        toggleScanContext: () => void
    }
) {
    const [sliceOrientationList, setSliceOrientationList] = useState([] as SliceOrientation[])
    const [slicePositions, setSlicePositions] = useState({ axial: 0, radial: 0 } as SlicePositionsCyl | SlicePositionsXyz)
    const [urlStore, setUrlStore] = useUrlState()

    useEffect(() => {
        if (sliceList.length > 0) {
            if (scanAxes === ScanAxes.CYL) {
                setUrlStore({ xy: undefined, yz: undefined, xz: undefined }); // clear other axes to avoid UX confusion
                const axial = getValidSlicePosition(sliceList, SliceOrientation.AXIAL, urlStore?.axial);
                const radial = getValidSlicePosition(sliceList, SliceOrientation.RADIAL, urlStore?.radial);
                setSliceOrientationList([SliceOrientation.RADIAL, SliceOrientation.AXIAL]);
                setSlicePositions({ radial, axial });
            }
            if (scanAxes === ScanAxes.XYZ) {
                setUrlStore({ axial: undefined, radial: undefined });
                const xy = getValidSlicePosition(sliceList, SliceOrientation.XY, urlStore?.xy);
                const xz = getValidSlicePosition(sliceList, SliceOrientation.XZ, urlStore?.xz);
                const yz = getValidSlicePosition(sliceList, SliceOrientation.YZ, urlStore?.yz);
                setSlicePositions({ xy, xz, yz });
                setSliceOrientationList([SliceOrientation.XY, SliceOrientation.XZ, SliceOrientation.YZ]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sliceList])

    const updateSlicePositions = (position: number, orientation: SliceOrientation) => {
        const update = { ...slicePositions, [orientation]: position }
        throttleSlicePosition(update)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const throttleSlicePosition = useCallback(throttle((update) => {
        setSlicePositions(update); setUrlStore(update)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, 100), [])


    const minSlicePositions = () => {
        if (scanAxes === ScanAxes.CYL) {
            return {
                axial: Math.min(...sliceList.filter((slice: SliceRow) => slice.orientation === SliceOrientation.AXIAL)
                    .map((slice: SliceRow) => slice.position)),
                radial: Math.min(...sliceList.filter((slice: SliceRow) => slice.orientation === SliceOrientation.RADIAL)
                    .map((slice: SliceRow) => slice.position))
            } as SlicePositionsCyl
        }
        if (scanAxes === ScanAxes.XYZ) {
            return {
                xy: Math.min(...sliceList.filter((slice: SliceRow) => slice.orientation === SliceOrientation.XY).map((slice: SliceRow) => slice.position)),
                xz: Math.min(...sliceList.filter((slice: SliceRow) => slice.orientation === SliceOrientation.XZ).map((slice: SliceRow) => slice.position)),
                yz: Math.min(...sliceList.filter((slice: SliceRow) => slice.orientation === SliceOrientation.YZ).map((slice: SliceRow) => slice.position))
            } as SlicePositionsXyz
        }
        return {} as SlicePositionsCyl
    }


    const xlScreenGrid = () => { return scanAxes === ScanAxes.XYZ ? 4 : 6 }
    if (sliceOrientationList.length > 0 && minSlicePositions()) return (
        <>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <ScanMetaData scanData={scanData} voxelSize={sliceList[0].voxel_size_mm} />
                {
                    scanMetadata && scanMetadata.length > 0 &&
                    <Button
                        data-testid="scan_context_button"
                        sx={{
                            backgroundColor: "#192A51",
                            height: "71px",
                            fontSize: "11px",
                            marginLeft: '10px', // space between elements
                        }}
                        onClick={toggleScanContext}
                    >
                        Scan <br />Context
                    </Button>
                }
            </div>


            <Grid container
                spacing={1}
                direction="row"
                justifyContent="center"
                sx={{ mb: 3 }}
            >
                {
                    sliceOrientationList.map((sliceOrientation: SliceOrientation) => {
                        return (
                            <Grid key={sliceOrientation} item xs={12} md={6} xl={xlScreenGrid()}>
                                <OneAxisSliceView
                                    sliceList={sliceList.filter((slice: SliceRow) => slice.orientation === sliceOrientation)}
                                    sliceOrientation={sliceOrientation}
                                    scanData={scanData}
                                    handleSliderChange={(e, newValue) => { updateSlicePositions(newValue as number, sliceOrientation) }}
                                    slicePositions={slicePositions}
                                    minSlicePositions={minSlicePositions()}
                                    isDemoMode={isDemoMode}
                                    toggleScanContext={toggleScanContext}
                                // TODO: remove ^ action down, replace with data down.
                                />
                            </Grid>
                        )
                    })
                }
                {
                    // This is kinda dumb but whatever- makes it look nice
                    sliceOrientationList.length === 1 &&
                    <Grid item xs={12} md={6} xl={xlScreenGrid()}></Grid>
                }
                {
                    sliceOrientationList.length >= 1 && sliceOrientationList.length < 3 && scanAxes === ScanAxes.XYZ &&
                    <Grid item xs={12} md={6} xl={xlScreenGrid()}></Grid>
                }
            </Grid>
        </>
    )
    return (null);
}
