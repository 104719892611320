import { dot, frame, plot, pointer, ruleX, ruleY, tip } from "@observablehq/plot";
import { group, max, min } from "d3";
import { AutomatedInspectionMetrics } from ".";
import { colorScheme, overlayColorScheme } from "../colorScheme";
import { ChartSeriesOptions, formatMetricName, plotBackground, PlotDataWithX, ScanPreviewState, tooltipBackground, tooltipText, XAxisOptions } from "./dashboard";


export const createOneFacet = (
    facetData: PlotDataWithX[],
    category: string,
    totalWidth: number,
    totalHeight: number,
    xAxisOption: XAxisOptions,
    horizontalLines: number[],
    previewState: ScanPreviewState,
    xDomain: [number, number],
    showXAxis: boolean,
    margins: {
        top: number,
        left: number,
        bottom: number,
        right: number,
    },
    xInset: number,
    yInset: number,
    tickSize: number,
) => {
    const isDate = xAxisOption === XAxisOptions.DATE;
    function computeMinMax(facetData: PlotDataWithX[], isDate: boolean) {
        return Array.from(
            group(facetData, d => isDate ? d.date : d.x),
            ([key, values]) => {
                const filteredMinValues = values.filter(d => d.series === ChartSeriesOptions.MIN && d.value !== null);
                const filteredMaxValues = values.filter(d => d.series === ChartSeriesOptions.MAX && d.value !== null);
                const yMin = min(filteredMinValues, d => d.value as number);
                const yMax = max(filteredMaxValues, d => d.value as number);
                if (yMin !== undefined && yMax !== undefined) {
                    return isDate
                        ? { date: new Date(key), yMin, yMax }
                        : { x: String(key), yMin, yMax };
                }
                return null;
            }
        ).filter(Boolean) as { x?: string; date?: Date; yMin: number; yMax: number }[];
    }
    const xAxis = isDate ? "date" : "x";
    const xAxisType = isDate ? "time" : "linear";
    const uniqueXValues = Array.from(new Set(facetData.map(d => d.x)));

    const tickNumber = () => {
        if (!showXAxis) return 0;
        if (isDate) return totalWidth / 60
        const idealTickNumber = totalWidth / 100
        if (uniqueXValues.length === 1) return 1;
        if ((idealTickNumber > uniqueXValues.length)) return uniqueXValues.length - 1;
        return idealTickNumber;
    }

    const augmentedXInset = () => {
        if (uniqueXValues.length < 10) return 100;
        return xInset;
    }
    return plot({
        width: totalWidth,
        height: totalHeight,
        className: "plot-chart-class",
        marginTop: margins.top,
        marginLeft: margins.left,
        marginBottom: margins.bottom,
        marginRight: margins.right,
        insetBottom: yInset,
        insetTop: yInset,
        x: {
            type: xAxisType,
            label: null,
            labelArrow: false,
            tickFormat: isDate
                ? null
                : d => {
                    const label = facetData.find(e => e.x === d)?.sn ?? "";
                    return label.length > 30 ? label.slice(0, 40) + "..." : label;
                },
            tickRotate: -25,
            tickSize: tickSize,
            ticks: tickNumber(),
            grid: false,
            domain: xDomain,
            inset: augmentedXInset(),
        },
        y: {
            label: `${formatMetricName(category as AutomatedInspectionMetrics)}`,
            labelAnchor: "top",
            nice: true,
            labelArrow: false,
            labelOffset: margins.left / 2,
        },
        grid: true,
        color: {
            domain: [ChartSeriesOptions.MIN, ChartSeriesOptions.MAX, ChartSeriesOptions.MEAN, ChartSeriesOptions.MEDIAN],
            range: [colorScheme.min, colorScheme.max, colorScheme.average, colorScheme.median]
        },
        marks: [
            frame({ fill: plotBackground }),
            ruleX(
                computeMinMax(facetData, isDate),
                {
                    x: xAxis, y1: "yMin", y2: "yMax", stroke: "white", clip: true,
                    strokeWidth: 0.5
                },
            ),
            dot(facetData.filter(d => d.value !== null), {
                x: xAxis,
                y: "value",
                fill: "series",
                clip: true,
            }),
            // Preview Dot
            dot(facetData.filter(
                d => d.scanId === previewState.scanId &&
                    d.sliceId === previewState.sliceId &&
                    d.series === previewState.series &&
                    d.metric === previewState.metric
            ), {
                x: xAxis,
                y: "value",
                fill: "series",
                stroke: "red",
                strokeWidth: 3,
                clip: true,
            }),
            ruleY(horizontalLines, { stroke: overlayColorScheme.pink }),
            tip(
                facetData.filter(d => d.value !== null),
                pointer({
                    x: xAxis,
                    y: "value",
                    title: d => tooltipText(d, xAxisOption),
                    fill: tooltipBackground,
                    fontSize: 12,
                    maxRadius: 15, // how close to the point the mouse has to be to trigger the tooltip
                })
            )
        ],
        figure: false
    });
};
