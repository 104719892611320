import { AutomatedInspectionMetrics, metricDisplayUnitSliceViewer } from ".";
import { formatIsoDateTime } from "..";
import { ScanList, SliceOrientation } from "../../types";

export const tooltipBackground = "#02081f99"
export const plotBackground = "#FFFFFF08"

// TODO: rename or remove when we drop V1
export interface MetrologyDataRequest {
    metric_id: number,
    request_id: number,
    request_name: string,
    scan_id: number,
    max_value: number,
    min_value: number,
    avg_value: number,
}

export interface ScanPreviewState {
    scanId: number | null,
    sliceId: number | null,
    metric: AutomatedInspectionMetrics | null,
    series: string | null,
    value: number | null,
}

export interface InspectionChartData {
    metric_internal_name: AutomatedInspectionMetrics,
    orientation: SliceOrientation,
    request_id: number,
    request_name: string,
    scan_date: Date,
    scan_id: number,
    sn: string,
    min: number,
    max: number,
    mean: number,
    median: number,
    min_slice_id: number,
    max_slice_id: number,
}



export enum Granularity {
    SCAN = 'scan_id',
    REQUEST = 'request_id',
}

export enum XAxisOptions {
    SCAN_ID = 'scan_id',
    DATE = 'date',
    CROSS_COMPARE = 'cross_compare',
    BATCH = 'batch'
}

export enum ChartSeriesOptions {
    MIN = "Min",
    MAX = "Max",
    MEAN = "Mean",
    MEDIAN = "Median"
}

export interface PlotData {
    scanId: number;
    sn: string;
    metric: string;
    series: ChartSeriesOptions;
    sliceId: number | null;
    value: number | null;
    batch: string;
    date: Date;
}

export interface PlotDataWithX extends PlotData {
    x: number;
}

export interface PlotDataV2 {
    x: number;
    scanId: number;
    metric: string;
    series: ChartSeriesOptions;
    sliceId: number | null;  // TODO: could have this be derived in parent component
    value: number | null;
}

export interface PlotDataDimData {
    scanId: number;
    // TODO: make a label and a tooltip instead
    sn: string;
    batch: string;
    date: Date;
}


export const formatMetricName = (metric: AutomatedInspectionMetrics) => {
    const units = metricDisplayUnitSliceViewer(metric)
    if (units === "") return displayNamer(metric)
    return displayNamer(metric) + " (" + metricDisplayUnitSliceViewer(metric) + ")"
}

export const transformData = (data: InspectionChartData[], xAxisOption: XAxisOptions): PlotDataWithX[] => {
    const processedData = xAxisOption === XAxisOptions.BATCH ? summarizeInspectionData(data) : data

    const uniqueMetrics = Array.from(new Set(processedData.map(({ metric_internal_name }) => metric_internal_name)));
    const uniqueScans = Array.from(new Set(processedData.map(({ scan_id }) => scan_id)));
    const scanIdsOnly = Array.from(new Set(processedData.map(d => String(d.scan_id))));
    const dataWithIndexedSN = processedData.map(d => ({
        ...d,
        x: scanIdsOnly.indexOf(String(d.scan_id)),
    }));
    // Generate all combinations of uniqueMetrics and uniqueScans a null for mean, if not present
    const baseMetricsNulled = uniqueMetrics.flatMap(metric =>
        uniqueScans.map((scan, index) => ({
            x: index,
            scanId: scan,
            metric: metric,
            series: ChartSeriesOptions.MEAN,
            sliceId: null,
            value: processedData.find(({ scan_id, metric_internal_name }) => scan_id === scan && metric_internal_name === metric)?.mean || null
        }))
    );

    // Existing transformation logic
    const transformedData = dataWithIndexedSN.flatMap(({
        scan_id,
        x,
        metric_internal_name,
        median,
        min,
        min_slice_id,
        max,
        max_slice_id
    }) => {
        const seriesData = [
            { series: ChartSeriesOptions.MEDIAN, value: median, sliceId: null },
            { series: ChartSeriesOptions.MIN, value: min, sliceId: min_slice_id },
            { series: ChartSeriesOptions.MAX, value: max, sliceId: max_slice_id },
        ];

        return seriesData.map(({ series, value, sliceId }) => ({
            x: x,
            scanId: scan_id,
            metric: metric_internal_name,
            series,
            sliceId,
            value,
        }));
    });

    const allCombinations = [...baseMetricsNulled, ...transformedData];

    return allCombinations.map(({ x, scanId, metric, series, sliceId, value }) => ({
        x,
        scanId,
        metric,
        series,
        sliceId,
        value,
        sn: processedData.find(({ scan_id }) => scan_id === scanId)?.sn || "",
        batch: processedData.find(({ scan_id }) => scan_id === scanId)?.request_name || "",
        date: processedData.find(({ scan_id }) => scan_id === scanId)?.scan_date || new Date(0)
    }));
};

// TODO: consolidate with the MetricDisplayNames?
export const displayNamer = (metric: AutomatedInspectionMetrics) => {
    switch (metric) {
        case AutomatedInspectionMetrics.ANODE_OVERHANG_ALL: return "Anode Overhang (All)";
        case AutomatedInspectionMetrics.ANODE_OVERHANG_TOP: return "Anode Overhang (Top)";
        case AutomatedInspectionMetrics.ANODE_OVERHANG_BOTTOM: return "Anode Overhang (Bottom)";
        case AutomatedInspectionMetrics.CATHODE_WIDTH: return "Cathode Width";
        case AutomatedInspectionMetrics.CAN_WALL_THICKNESS_MEAN: return "Can Wall Thickness";
        case AutomatedInspectionMetrics.CAN_INNER_DIAMETER_MEAN: return "Can Inner Diameter";
        case AutomatedInspectionMetrics.CAN_OUTER_DIAMETER_MEAN: return "Can Outer Diameter";
        case AutomatedInspectionMetrics.CATHODE_POSITION_TOP: return "Cathode Position (Top)";
        case AutomatedInspectionMetrics.CATHODE_POSITION_BOTTOM: return "Cathode Position (Bottom)";
        case AutomatedInspectionMetrics.CORE_EFFECTIVE_DIAMETER: return "Core Effective Diameter";
        case AutomatedInspectionMetrics.CAN_MAX_DENTING: return "Can Max Denting";
        case AutomatedInspectionMetrics.CORE_CONCENTRICITY: return "Core Concentricity";
        case AutomatedInspectionMetrics.ANODE_TAB_CURVATURE: return "Anode Tab Curvature";
        case AutomatedInspectionMetrics.LEFT_CRIMP_HEIGHT: return "Left Crimp Height";
        case AutomatedInspectionMetrics.RIGHT_CRIMP_HEIGHT: return "Right Crimp Height";
        case AutomatedInspectionMetrics.MAX_CRIMP_GROOVE_GAP: return "Max Crimp Groove Gap";
        case AutomatedInspectionMetrics.MIN_CRIMP_GROOVE_GAP: return "Min Crimp Groove Gap";
        case AutomatedInspectionMetrics.MEAN_CRIMP_GROOVE_GAP: return "Mean Crimp Groove Gap";
        case AutomatedInspectionMetrics.CORE_AREA: return "Core Area";
        case AutomatedInspectionMetrics.CORE_CIRCULARITY_MCC: return "Core Circularity (MCC)";
        case AutomatedInspectionMetrics.CORE_CIRCULARITY_DMIN_DMAX: return "Core Circularity (Dmin/Dmax)";
        case AutomatedInspectionMetrics.ANODE_OVERHANG_ASYMMETRY: return "Anode Overhang Asymmetry";
        case AutomatedInspectionMetrics.CAN_CIRCULARITY_DMIN_DMAX: return "Can Circularity (Dmin/Dmax)";
        case AutomatedInspectionMetrics.CORE_ANODE_GAP_WIDTH: return "Core Anode Gap Width";
        case AutomatedInspectionMetrics.CATHODE_TO_END_ANODE_TAB: return "Cathode to End Anode Tab";
        case AutomatedInspectionMetrics.MIDDLE_CATHODE_TAB_TO_MIDDLE_ANODE_TAB: return "Middle Cathode Tab to Middle Anode Tab";
        case AutomatedInspectionMetrics.CATHODE_START_TO_MIDDLE_CATHODE_TAB: return "Cathode Start to Middle Cathode Tab";
        case AutomatedInspectionMetrics.CRIMP_HEIGHT: return "Crimp Height";
        case AutomatedInspectionMetrics.CRIMP_GROOVE_GAP: return "Crimp Groove Gap";
        case AutomatedInspectionMetrics.ELECTRODE_BUCKLING_DETECTED: return "Electrode Buckling Detected";
        default:
            return metric
    }
}

export const makeLabel = (scanList: ScanList[], granularity: keyof MetrologyDataRequest, id: number) => {
    switch (granularity) {
        case Granularity.SCAN:
            return scanList.find((e) => e.scan_id === id)?.cell_sn.toString() || ""
        case Granularity.REQUEST:
            return scanList.find((e) => e.request_id === id)?.request_name.toString() || ""
        default:
            return granularity.toString() + ': ' + id.toString()
    }
}

export const makeTooltip = (scanList: ScanList[], granularity: keyof MetrologyDataRequest, id: number) => {
    switch (granularity) {
        case Granularity.SCAN:
            const item = scanList.find((e) => e.scan_id === id)
            return item?.cell_model_vendor.concat(" ", item.cell_model_name) || ""
        case Granularity.REQUEST:
            return scanList.find((e) => e.request_id === id)?.cell_model_vendor.toString() || ""
        default:
            return granularity.toString() + ': ' + id.toString()
    }
}





export const tooltipText = (d: PlotDataWithX, xAxisType: XAxisOptions) =>
    xAxisType === XAxisOptions.BATCH ?
        `${d.series}: ${d.value?.toPrecision(5)}
Batch: ${d.batch}` :
        `Batch: ${d.batch}
SN: ${d.sn}
Scan ID: ${d.scanId}
${d.series}: ${d.value?.toPrecision(5)}
Scan date: ${formatIsoDateTime(new Date(d.date))}`;

export const tooltipTextCrossCompare = (d: {
    x: number;
    y: number;
    series: string;
    sn: string;
    scanId: number;
    batch: string;
    date: Date;
}) =>
    `X: ${d.x.toPrecision(5)}
Y: ${d.y.toPrecision(5)}
Series: ${d.series}
SN: ${d.sn}
Scan ID: ${d.scanId}
Batch: ${d.batch}
Scan date: ${formatIsoDateTime(new Date(d.date))}`;


/**
*  Calculate inspection data for the dashboard by request.
*/
export function summarizeInspectionData(data: InspectionChartData[]): InspectionChartData[] {
    const summaryMap = new Map<string, InspectionChartData>();

    data.forEach(entry => {
        const { request_id, request_name, metric_internal_name, orientation, min, max, mean, median } = entry;
        const key = `${request_id}-${metric_internal_name}-${orientation}`; // Unique key per request and metric

        if (!summaryMap.has(key)) {
            summaryMap.set(key, {
                metric_internal_name,
                request_id,
                request_name,
                orientation,
                scan_id: request_id,
                sn: request_name,
                min,
                max,
                mean,
                median,
                // placeholder values aren't shown in the batch-level plot
                scan_date: new Date(0),
                min_slice_id: 0,
                max_slice_id: 0,
            });
        } else {
            const summary = summaryMap.get(key)!;
            summary.min = Math.min(summary.min, min);
            summary.mean = (summary.mean + mean) / 2; // Averaging all means
            summary.median = (summary.median + median) / 2; // Averaging all medians
            summary.max = Math.max(summary.max, max);
        }
    });

    return Array.from(summaryMap.values());
}
