import { AnnotationShapeOptions } from "../../types";
import { overlayColorScheme } from "../colorScheme";

export interface AutomatedInspectionTableData {
    result: number;
    display_name: string;
    display: boolean;
    description: string;
    display_units: string;
    internal_name: AutomatedInspectionMetrics;
    statistic?: StatsDisplayNames,
    color: string;
    group_id: number;
    group_name: string;
}


export interface CalculatedStatistics {
    min: number;
    max: number;
    mean: number;
    stdDev: number;
}

export enum StatsDisplayNames {
    MIN = "Minimum",
    MAX = "Maximum",
    MEAN = "Mean",
    STD_DEV = "Standard deviation"
}

/**
 * These are used in the scan/slice viewer (table and shapes)
 * Note: they're not all necessarily in the DB inspection_metrics table, presently, which is confusing right now.
 */
export enum AutomatedInspectionMetrics {
    // General metrics that come from the DB:
    CORE_AREA = "core_area",
    CORE_CIRCULARITY_DMIN_DMAX = "core_circularity_dmin_dmax",
    CORE_CIRCULARITY_MCC = "core_circularity_mcc",
    CAN_INNER_DIAMETER_MEAN = "can_inner_diameter_mean",
    CAN_OUTER_DIAMETER_MEAN = "can_outer_diameter_mean",
    ELECTRODE_BUCKLING_DETECTED = "electrode_buckling_detected",
    INSUFFICIENT_ANODE_OVERHANG = "insufficient_anode_overhang",
    CAN_CIRCULARITY_DMIN_DMAX = "can_circularity_dmin_dmax",
    CAN_MAX_DENTING = "can_max_denting",
    CORE_EFFECTIVE_DIAMETER = "core_effective_diameter",
    CORE_CONCENTRICITY = "core_concentricity",
    ANODE_TAB_CURVATURE = "anode_tab_curvature",
    CORE_ANODE_GAP_WIDTH = "core_anode_gap_width",
    CATHODE_TO_END_ANODE_TAB = "cathode_to_end_anode_tab",
    MIDDLE_CATHODE_TAB_TO_MIDDLE_ANODE_TAB = "middle_cathode_tab_to_middle_anode_tab",
    CATHODE_START_TO_MIDDLE_CATHODE_TAB = "cathode_start_to_middle_cathode_tab",

    // Gotcha: these are in the inspection_metrics in the db but don't have to be:
    CAN_WALL_THICKNESS_MEAN = "can_wall_thickness_mean",
    ANODE_OVERHANG_ALL = "anode_overhang",
    ANODE_OVERHANG_TOP = "anode_overhang_top",
    ANODE_OVERHANG_BOTTOM = "anode_overhang_bottom",
    ANODE_OVERHANG_ASYMMETRY = "anode_overhang_asymmetry",
    CATHODE_WIDTH = "cathode_width",
    CATHODE_POSITION_TOP = "cathode_position_top",
    CATHODE_POSITION_BOTTOM = "cathode_position_bottom",
    // Note: these ones are a little funny for now. we'll probably revamp this later:
    LEFT_CRIMP_HEIGHT = "left_crimp_height",
    RIGHT_CRIMP_HEIGHT = "right_crimp_height",

    // These are combined to be MEAN_CRIMP_GROOVE_GAP
    MAX_CRIMP_GROOVE_GAP = "max_crimp_groove_gap",
    MIN_CRIMP_GROOVE_GAP = "min_crimp_groove_gap",
    // not in the db inspection_metrics table:
    MEAN_CRIMP_GROOVE_GAP = "mean_crimp_groove_gap",

    CRIMP_GROOVE_GAP = "crimp_groove_gap",
    CRIMP_HEIGHT = "crimp_height",
}

/**
 * This is the order that the automated inspection metrics should be displayed in the scan/slice viewer.
 */
export const automatedListOrder = [
    AutomatedInspectionMetrics.CORE_AREA,
    AutomatedInspectionMetrics.CORE_CIRCULARITY_DMIN_DMAX,
    AutomatedInspectionMetrics.CORE_EFFECTIVE_DIAMETER,
    AutomatedInspectionMetrics.CORE_CIRCULARITY_MCC,
    AutomatedInspectionMetrics.CORE_CONCENTRICITY,
    AutomatedInspectionMetrics.CAN_INNER_DIAMETER_MEAN,
    AutomatedInspectionMetrics.CAN_OUTER_DIAMETER_MEAN,
    AutomatedInspectionMetrics.CAN_WALL_THICKNESS_MEAN,
    AutomatedInspectionMetrics.CAN_CIRCULARITY_DMIN_DMAX,
    AutomatedInspectionMetrics.CAN_MAX_DENTING,
    AutomatedInspectionMetrics.ELECTRODE_BUCKLING_DETECTED,
    AutomatedInspectionMetrics.ANODE_TAB_CURVATURE,
    AutomatedInspectionMetrics.CORE_ANODE_GAP_WIDTH,
    AutomatedInspectionMetrics.CATHODE_TO_END_ANODE_TAB,
    AutomatedInspectionMetrics.MIDDLE_CATHODE_TAB_TO_MIDDLE_ANODE_TAB,
    AutomatedInspectionMetrics.CATHODE_START_TO_MIDDLE_CATHODE_TAB,
    AutomatedInspectionMetrics.ANODE_OVERHANG_ALL,
    AutomatedInspectionMetrics.ANODE_OVERHANG_ASYMMETRY,
    AutomatedInspectionMetrics.ANODE_OVERHANG_TOP,
    AutomatedInspectionMetrics.ANODE_OVERHANG_BOTTOM,
    AutomatedInspectionMetrics.CATHODE_WIDTH,
    AutomatedInspectionMetrics.CATHODE_POSITION_TOP,
    AutomatedInspectionMetrics.CATHODE_POSITION_BOTTOM,
    AutomatedInspectionMetrics.INSUFFICIENT_ANODE_OVERHANG,
    AutomatedInspectionMetrics.LEFT_CRIMP_HEIGHT,
    AutomatedInspectionMetrics.RIGHT_CRIMP_HEIGHT,
    AutomatedInspectionMetrics.MAX_CRIMP_GROOVE_GAP,
    AutomatedInspectionMetrics.MIN_CRIMP_GROOVE_GAP,
    AutomatedInspectionMetrics.MEAN_CRIMP_GROOVE_GAP,
] as string[]


export const metricDisplayUnitSliceViewer = (metric: string) => {
    switch (metric) {
        case AutomatedInspectionMetrics.ANODE_OVERHANG_ALL:
        case AutomatedInspectionMetrics.ANODE_OVERHANG_TOP:
        case AutomatedInspectionMetrics.ANODE_OVERHANG_BOTTOM:
        case AutomatedInspectionMetrics.CATHODE_WIDTH:
        case AutomatedInspectionMetrics.CAN_WALL_THICKNESS_MEAN:
        case AutomatedInspectionMetrics.CAN_INNER_DIAMETER_MEAN:
        case AutomatedInspectionMetrics.CAN_OUTER_DIAMETER_MEAN:
        case AutomatedInspectionMetrics.CATHODE_POSITION_TOP:
        case AutomatedInspectionMetrics.CATHODE_POSITION_BOTTOM:
        case AutomatedInspectionMetrics.CORE_EFFECTIVE_DIAMETER:
        case AutomatedInspectionMetrics.CAN_MAX_DENTING:
        case AutomatedInspectionMetrics.CORE_CONCENTRICITY:
        case AutomatedInspectionMetrics.ANODE_TAB_CURVATURE:
        case AutomatedInspectionMetrics.CORE_ANODE_GAP_WIDTH:
        case AutomatedInspectionMetrics.LEFT_CRIMP_HEIGHT:
        case AutomatedInspectionMetrics.RIGHT_CRIMP_HEIGHT:
        case AutomatedInspectionMetrics.MAX_CRIMP_GROOVE_GAP:
        case AutomatedInspectionMetrics.MIN_CRIMP_GROOVE_GAP:
        case AutomatedInspectionMetrics.MEAN_CRIMP_GROOVE_GAP:
            return "mm"
        case AutomatedInspectionMetrics.CORE_AREA:
            return "mm²"
        case AutomatedInspectionMetrics.CORE_CIRCULARITY_MCC:
        case AutomatedInspectionMetrics.CORE_CIRCULARITY_DMIN_DMAX:
        case AutomatedInspectionMetrics.ANODE_OVERHANG_ASYMMETRY:
        case AutomatedInspectionMetrics.CAN_CIRCULARITY_DMIN_DMAX:
            return "%"
        case AutomatedInspectionMetrics.CATHODE_TO_END_ANODE_TAB:
        case AutomatedInspectionMetrics.MIDDLE_CATHODE_TAB_TO_MIDDLE_ANODE_TAB:
        case AutomatedInspectionMetrics.CATHODE_START_TO_MIDDLE_CATHODE_TAB:
            return "°"
        default:
            return ""
    }
}

export const MetricDisplayNames = [
    // Source of truth for naming in the slice view tables:
    {
        display_name: "Minimum circumscribed circle",
        description: "Circularity of the core, as defined by the maximum deviation from a minimum circumscribed circle (MCC) and normalized by radius",
        internal_name: AutomatedInspectionMetrics.CORE_CIRCULARITY_MCC,
    },
    {
        display_name: "Eccentricity",
        description: "Circularity of the core, as obtained via the ratio of the min and max diameters from an ellipse fit",
        internal_name: AutomatedInspectionMetrics.CORE_CIRCULARITY_DMIN_DMAX,
    },
    {
        display_name: "Mean inner diameter",
        description: "Inner diameter of the can, averaged over a slice",
        internal_name: AutomatedInspectionMetrics.CAN_INNER_DIAMETER_MEAN,
    },
    {
        display_name: "Mean outer diameter",
        description: "Outer diameter of the can, averaged over a slice",
        internal_name: AutomatedInspectionMetrics.CAN_OUTER_DIAMETER_MEAN,
    },
    {
        display_name: "Area",
        description: "Area of the core (exact definition may vary based on the cell model)",
        internal_name: AutomatedInspectionMetrics.CORE_AREA,
    },
    {
        display_name: "Cathode buckling detected?",
        description: "True if electrode buckling has been detected in the jellyroll",
        internal_name: AutomatedInspectionMetrics.ELECTRODE_BUCKLING_DETECTED,
    },
    {
        display_name: "Mean wall thickness",
        description: "Thickness of the can wall, averaged over a slice (outer radius - inner radius)",
        internal_name: AutomatedInspectionMetrics.CAN_WALL_THICKNESS_MEAN,
    },
    {
        display_name: "Cathode width",
        description: "Measured cathode width (jellyroll \"height\" in an axial slice). Currently, this measurement is approximated by a straight line between the top and bottom endpoints.",
        internal_name: AutomatedInspectionMetrics.CATHODE_WIDTH,
    },
    {
        display_name: "Cathode position - top",
        description: "Cathode wrap \"height\" at the top of the cell. The position is defined from the top of the image",
        internal_name: AutomatedInspectionMetrics.CATHODE_POSITION_TOP,
    },
    {
        display_name: "Cathode position - bottom",
        description: "Cathode wrap \"height\" at the bottom of the cell. The position is defined from the top of the image",
        internal_name: AutomatedInspectionMetrics.CATHODE_POSITION_BOTTOM,
    },
    {
        display_name: "Anode overhang - all",
        description: "Anode overhang lengths detected at the top and bottom of the cell",
        internal_name: AutomatedInspectionMetrics.ANODE_OVERHANG_ALL,
    },
    {
        display_name: "Anode overhang - bottom",
        description: "Anode overhang lengths detected at the bottom of the cell",
        internal_name: AutomatedInspectionMetrics.ANODE_OVERHANG_BOTTOM,
    },
    {
        display_name: "Anode overhang - top",
        description: "Anode overhang length detected at the top of the cell",
        internal_name: AutomatedInspectionMetrics.ANODE_OVERHANG_TOP,
    },
    {
        display_name: "Top/bottom overhang asymmetry",
        description: "A measure of the asymmetry of the overhangs, where 0% is perfectly centered. A positive value indicates average overhang is longer at the top, and a negative value indicates average overhang is longer at the bottom.",
        internal_name: AutomatedInspectionMetrics.ANODE_OVERHANG_ASYMMETRY,
    },
    {
        display_name: "Electrode overhang violation?",
        description: "True if insufficient anode overhang has been detected within the slice",
        internal_name: AutomatedInspectionMetrics.INSUFFICIENT_ANODE_OVERHANG,
    },
    {
        display_name: "Eccentricity",
        description: "Circularity of the can, as obtained via the ratio of the min and max diameters from an ellipse fit",
        internal_name: AutomatedInspectionMetrics.CAN_CIRCULARITY_DMIN_DMAX,
    },
    {
        display_name: "Max denting",
        description: "Largest dent detected, as defined by the maximum inward deviation from an ellipse fit. Only dents larger than 3 voxels are flagged.",
        internal_name: AutomatedInspectionMetrics.CAN_MAX_DENTING,
    },
    {
        display_name: "Effective diameter",
        description: "Geometric mean (√ab) of the major and the minor axes from an ellipse fit",
        internal_name: AutomatedInspectionMetrics.CORE_EFFECTIVE_DIAMETER,
    },
    {
        display_name: "Concentricity",
        description: "Distance from the can center (center of the image) to the center of an ellipse fit of the core",
        internal_name: AutomatedInspectionMetrics.CORE_CONCENTRICITY,
    },
    {
        display_name: "Anode tab curvature",
        description: "Curvature of the core anode tab, as defined by the radius of curvature of a circle fit",
        internal_name: AutomatedInspectionMetrics.ANODE_TAB_CURVATURE,
    },
    {
        display_name: "Mean anode gap width",
        description: "Mean distance between the anode core wraps form cathode start point to anode tab",
        internal_name: AutomatedInspectionMetrics.CORE_ANODE_GAP_WIDTH,
    },
    {
        display_name: "Cathode to end anode tab",
        description: "Angle from the cathode start point to the end of the anode tab",
        internal_name: AutomatedInspectionMetrics.CATHODE_TO_END_ANODE_TAB,
    },
    {
        display_name: "Middle cathode tab to middle anode tab",
        description: "Angle from the middle of the cathode tab to the middle of the anode tab",
        internal_name: AutomatedInspectionMetrics.MIDDLE_CATHODE_TAB_TO_MIDDLE_ANODE_TAB,
    },
    {
        display_name: "Cathode start to middle cathode tab",
        description: "Angle from the cathode start point to the middle of the cathode tab",
        internal_name: AutomatedInspectionMetrics.CATHODE_START_TO_MIDDLE_CATHODE_TAB,
    },

    {
        display_name: "Left crimp height",
        description: "Height of the left crimp, as defined by the vertical distance between the top and bottom endpoints",
        internal_name: AutomatedInspectionMetrics.LEFT_CRIMP_HEIGHT,
    },
    {
        display_name: "Right crimp height",
        description: "Height of the right crimp, as defined by the vertical distance between the top and bottom endpoints",
        internal_name: AutomatedInspectionMetrics.RIGHT_CRIMP_HEIGHT,
    },
    {
        display_name: "Max crimp groove gap",
        description: "Maximum of the minimum gap between the crimp grooves on each side, as defined by the Euclidean distance between the top and bottom endpoints",
        internal_name: AutomatedInspectionMetrics.MAX_CRIMP_GROOVE_GAP,
    },
    {
        display_name: "Min crimp groove gap",
        description: "Minimum of the minimum gap between the crimp grooves on each side, as defined by the Euclidean distance between the top and bottom endpoints",
        internal_name: AutomatedInspectionMetrics.MIN_CRIMP_GROOVE_GAP,
    },
    {
        display_name: "Mean crimp groove gap",
        description: "Mean of the minimum gap between the crimp grooves on each side of a slice, as defined by the Euclidean distance between the top and bottom endpoints",
        internal_name: AutomatedInspectionMetrics.MEAN_CRIMP_GROOVE_GAP,
    },
    {
        display_name: "Crimp groove gap",
        description: "The gap between the crimp grooves on each side of a slice, as defined by the Euclidean distance between the top and bottom endpoints",
        internal_name: AutomatedInspectionMetrics.CRIMP_GROOVE_GAP,
    },
    {
        display_name: "Crimp height",
        description: "Height of the crimp, as defined by the vertical distance between the top and bottom endpoints",
        internal_name: AutomatedInspectionMetrics.CRIMP_HEIGHT,
    },
]



/**
 * Calculate min, max, mean, and standard deviation of an array of numbers.
 * @param numbers - an array of numbers
 * @returns an object with min, max, mean, and standard deviation
 */
export function calculateStats(numbers: number[]): CalculatedStatistics {
    if (numbers.length === 0) { return { min: 0, max: 0, mean: 0, stdDev: 0 }; }
    let min = numbers[0], max = numbers[0], sum = 0, varianceSum = 0;
    // for loops are faster than array.map and .reduce functions, allegedly.
    for (const num of numbers) {
        if (num < min) min = num;
        if (num > max) max = num;
        sum += num;
    }
    const mean = sum / numbers.length;
    for (const num of numbers) {
        varianceSum += (num - mean) ** 2;
    }
    const stdDev = Math.sqrt(varianceSum / numbers.length);
    return { min, max, mean, stdDev };
}


export const conditionallyApplyPadding = (
    coordinates: number[] | number[][],
    shapeType: AnnotationShapeOptions,
    imageMeasurementPadding: { horizontal: number, vertical: number }
) => {
    switch (shapeType) {
        case AnnotationShapeOptions.ELLIPSE_AND_LINES:
            // TODO check length of array? or do that upstream in API?
            return coordinates.map((e, index) => {
                if (Array.isArray(e)) { return e }
                // TODO: yikes.
                // ellipse and two lines [x,y,r1,r2,theta,x1,y1,x2,y2,x3,y3,x4,y4]
                if ([0, 5, 7, 9, 11].includes(index)) return e + imageMeasurementPadding.horizontal
                if ([1, 6, 8, 10, 12].includes(index)) return e + imageMeasurementPadding.vertical
                return e
            })
        case AnnotationShapeOptions.CIRCLE_AND_LINE:
            return coordinates.map((e, index) => {
                if (Array.isArray(e)) { return e }
                // Circle and line [x,y,r1,x1,y1,x2,y2]
                if ([0, 3, 5].includes(index)) return e + imageMeasurementPadding.horizontal
                if ([1, 4, 6].includes(index)) return e + imageMeasurementPadding.vertical
                return e
            })
        default:
            return coordinates.map((e) => {
                if (Array.isArray(e)) {
                    // TODO: could type check more here with .length
                    return [e[0] + imageMeasurementPadding.horizontal, e[1] + imageMeasurementPadding.vertical]
                }
                return e
            })
    }
}

export const calculateMaxMin = (arr: number[]) => { return { max: Math.max(...arr), min: Math.min(...arr) } }


/**
 * A little janky way of coloring and highlighting the min and max values of a given metric. TODO: refactor this logic later
 */
export const colorAndHighlightMaxMin = (value: number, minMax: { min: number, max: number }, highlightedStat: StatsDisplayNames | null) => {
    let color = overlayColorScheme.blue.concat("98") // adding 98 adds opacity to the color, so it can be highlighted later.
    if (value === minMax.min) {
        color = overlayColorScheme.orange.concat("98")
        if (highlightedStat === StatsDisplayNames.MIN) color = overlayColorScheme.orange
    }
    if (value === minMax.max) {
        color = overlayColorScheme.green.concat("98")
        if (highlightedStat === StatsDisplayNames.MAX) color = overlayColorScheme.green
    }
    if (highlightedStat && [StatsDisplayNames.MEAN, StatsDisplayNames.STD_DEV].includes(highlightedStat)) color = overlayColorScheme.blue
    return color
}
