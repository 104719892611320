import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAuthTokenAndAccessApi } from '../../../auth/authHooks';
import { AnnotationShapeList, ManualMeasurementResults, ScanList, SliceOrientation, SliceRow } from '../../../types';
import { getFirstThumbnailImage, getInspectionResults, getSliceList, getValidSlicePosition } from '../../../utils';
import { overlayColorScheme } from '../../../utils/colorScheme';
import { AutomatedInspectionMetrics } from '../../../utils/inspection';
import { ChartSeriesOptions, ScanPreviewState } from '../../../utils/inspection/dashboard';
import { getListOfShapes } from '../../../utils/inspection/inspectionShapes';
import BasicLoadingIndicator from '../../BasicLoadingIndicator';
import ScanImagePreviewer from './ScanImagePreviewer';
import TopDataTable from './TopDataTable';

export default function ScanPreviewerLayout(
    {
        rightPanelWidth,
        previewState,
        selectedOrientation,
        isDemoMode,
        scanList,
        clearSelection,
    }: {
        rightPanelWidth: number,
        previewState: ScanPreviewState,
        selectedOrientation: SliceOrientation | null,
        isDemoMode: boolean,
        scanList: ScanList[],
        clearSelection: React.Dispatch<React.SetStateAction<void>>,
    }) {

    const { fetchData } = useAuthTokenAndAccessApi();
    const [sliceList, setSliceList] = useState<SliceRow[]>([] as SliceRow[])
    const [visibleImage, setVisibleImage] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [rawImageDimensions, setRawImageDimensions] = useState({ height: 0, width: 0 });
    const [annotationList, setAnnotationList] = useState([] as AnnotationShapeList[])

    useEffect(() => {
        if (previewState.scanId) {
            setIsLoading(true)
            setVisibleImage("")
            setAnnotationList([] as AnnotationShapeList[])
            setSliceList([] as SliceRow[])
            loadData().then(() => setIsLoading(false))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [previewState])

    async function loadData() {
        await getSliceList(previewState.scanId as number, isDemoMode, fetchData)
            .then((sliceList) => {
                if (sliceList) {
                    setSliceList(sliceList.sliceList)
                    return sliceList.sliceList
                }
            })
            .then((sliceList) => {
                if (previewState.sliceId !== null) getFirstThumbnailImage(previewState.sliceId, isDemoMode, fetchData)
                    .then((e) => {
                        if (e.dimensions && e.imageUrl) {
                            setVisibleImage(e.imageUrl)
                            setRawImageDimensions(e.dimensions)
                        }
                    })
                else {
                    if (selectedOrientation && sliceList) {
                        const slicePosition = getValidSlicePosition(sliceList, selectedOrientation);
                        const sliceId = sliceList.find((e) => e.position === slicePosition && e.orientation === selectedOrientation)?.slice_id
                        if (sliceId) {
                            getFirstThumbnailImage(sliceId, isDemoMode, fetchData)
                                .then((e) => {
                                    if (e.dimensions && e.imageUrl) {
                                        setVisibleImage(e.imageUrl)
                                        setRawImageDimensions(e.dimensions)
                                    }
                                })
                        }
                        return sliceId
                    }
                }
            })
            .then((sliceId) => {
                let sliceIdState = previewState.sliceId
                if (sliceId) sliceIdState = sliceId
                if (sliceIdState) getAndSetInspectionResults(
                    isDemoMode, previewState.scanId as number, selectedOrientation as SliceOrientation, sliceIdState)
            })
    }

    async function getAndSetInspectionResults(isDemoMode: boolean, scan_id: number, orientation: SliceOrientation, sliceId: number) {
        getInspectionResults(isDemoMode, scan_id, orientation, fetchData)
            .then((allScanInspections) => {
                if (allScanInspections && previewState.metric) {
                    // this is pretty hacky, but allows us to filter out the confusing other colors
                    const isCathodeOrElectrode = [
                        AutomatedInspectionMetrics.CATHODE_WIDTH,
                        AutomatedInspectionMetrics.ANODE_OVERHANG_ALL,
                        AutomatedInspectionMetrics.ANODE_OVERHANG_TOP,
                        AutomatedInspectionMetrics.ANODE_OVERHANG_BOTTOM,
                    ].includes(previewState.metric)

                    const showOnlyMinOrMax = (color: string) => {
                        if (previewState.series === ChartSeriesOptions.MIN && isCathodeOrElectrode) return color.slice(0, 7) === overlayColorScheme.orange
                        if (previewState.series === ChartSeriesOptions.MAX && isCathodeOrElectrode) return color.slice(0, 7) === overlayColorScheme.green
                        return true
                    }
                    const colorForPreview = () => {
                        if (previewState.series === ChartSeriesOptions.MIN) return overlayColorScheme.orange
                        if (previewState.series === ChartSeriesOptions.MAX) return overlayColorScheme.green
                        return overlayColorScheme.blue
                    }

                    let visibleMetricList = [previewState.metric] as string[]
                    // special cases where we have stored shapes under different metrics (due to many:many relationships)
                    switch (previewState.metric) {
                        case AutomatedInspectionMetrics.ANODE_OVERHANG_ASYMMETRY:
                            visibleMetricList = [AutomatedInspectionMetrics.ANODE_OVERHANG_ALL]
                            break
                        case AutomatedInspectionMetrics.CRIMP_GROOVE_GAP:
                            visibleMetricList = [AutomatedInspectionMetrics.MAX_CRIMP_GROOVE_GAP, AutomatedInspectionMetrics.MIN_CRIMP_GROOVE_GAP]
                            break
                        case AutomatedInspectionMetrics.CRIMP_HEIGHT:
                            visibleMetricList = [AutomatedInspectionMetrics.LEFT_CRIMP_HEIGHT, AutomatedInspectionMetrics.RIGHT_CRIMP_HEIGHT]
                            break
                    }

                    const annotationList = getListOfShapes(
                        [] as ManualMeasurementResults[],
                        0, null,// mouse action highlighting stuff here, dropping for now
                        sliceId,
                        visibleMetricList,
                        allScanInspections
                    )
                        //filtering out all the non-highlighted shapes:
                        .filter(e => showOnlyMinOrMax(e.color))
                        // drops all opacity values (opacity is how we "highlight")
                        .map(e => ({ ...e, color: colorForPreview() }))
                    if (annotationList) { setAnnotationList(annotationList) }
                }
            })
    }

    const slicePosition = sliceList.find((e) => e.slice_id === previewState.sliceId)?.position
    return <Box sx={{ p: 1, pr: 1, minWidth: rightPanelWidth, width: rightPanelWidth }} >
        <Stack direction="row" justifyContent="space-between" alignItems="end" >
            <Typography variant="body1" >
            </Typography>
            <IconButton onClick={() => clearSelection()} size="small" >
                <CloseIcon />
            </IconButton>
        </Stack>
        <Stack direction="column" >
            {
                !isLoading && selectedOrientation ?
                    <>
                        <TopDataTable
                            previewState={previewState}
                            selectedOrientation={selectedOrientation}
                            scanList={scanList}
                            selectedSlicePosition={slicePosition === undefined ? null : slicePosition}
                            isDemoMode={isDemoMode}
                        />
                        <ScanImagePreviewer
                            visibleImage={visibleImage}
                            imageDimensions={rawImageDimensions}
                            viewerPanelWidth={rightPanelWidth * .97}
                            orientation={selectedOrientation}
                            voxelSize={sliceList[0]?.voxel_size_mm || 0}
                            annotationList={annotationList}
                        />
                    </> :
                    <BasicLoadingIndicator message='Loading' />
            }
        </Stack>
    </Box>
}
