import { Add as AddIcon, Close as CloseIcon } from "@mui/icons-material";
import { Button, IconButton, List, ListItem, Stack, TextField } from "@mui/material";
import { useState } from "react";

interface AddReferenceLinesProps {
    values: number[];
    setValues: (values: number[]) => void;
}

/**
 * adds a horizontal line (rule mark) to all charts at a max of 3 values.
 */
export default function HorizontalReferenceLines({ values, setValues }: AddReferenceLinesProps) {
    const [inputValues, setInputValues] = useState<string[]>(values.map(String));

    const addValueField = () => {
        // Max 3 reference lines:
        if (values.length < 3) {
            setInputValues([...inputValues, ""]);
        }
    };

    const handleValueChange = (index: number, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newInputValues = [...inputValues];
        newInputValues[index] = event.target.value;
        setInputValues(newInputValues);

        // Only update the external values state if all fields contain valid numbers
        const parsedValues = newInputValues.map((val) => parseFloat(val)).filter((num) => !isNaN(num));
        if (parsedValues.length === newInputValues.length) {
            setValues(parsedValues);
        }
    };

    const removeValueField = (index: number) => {
        const newInputValues = inputValues.filter((_, i) => i !== index);
        setInputValues(newInputValues);

        // Also update the external values state
        const parsedValues = newInputValues.map((val) => parseFloat(val)).filter((num) => !isNaN(num));
        setValues(parsedValues);
    };

    return (
        <>
            <Stack direction="column" spacing={0} justifyContent={"flex-start"}>
                <List sx={{ p: 0, m: 0 }}>
                    {inputValues.map((value, index) => (
                        <ListItem key={index} sx={{ display: "flex", alignItems: "center", pb: 1, pl: 1, pt: 0 }}>
                            <TextField
                                type="number"
                                value={value}
                                size="small"
                                onChange={(event) => handleValueChange(index, event)}
                                sx={{ width: 130 }}
                            />
                            <IconButton onClick={() => removeValueField(index)} >
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </ListItem>
                    ))}
                </List>
                {inputValues.length < 3 && inputValues.every((val) => val.trim() !== "") && (
                    <Button onClick={addValueField} variant='outlined' size="small" fullWidth sx={{ pl: 1, pr: 1 }}>
                        <AddIcon fontSize="small" />Add horizontal line (on all charts)
                    </Button>

                )}
            </Stack >
        </>

    );
}
