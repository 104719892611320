import CloudDoneSharpIcon from '@mui/icons-material/CloudDoneSharp';
import CloudOffSharpIcon from '@mui/icons-material/CloudOffSharp';
import Box from '@mui/material/Box';
import Tooltip from "@mui/material/Tooltip";
import Typography from '@mui/material/Typography';
import { overlayColorScheme } from '../../utils/colorScheme';


export default function GlimpseboxQueueConnected(
    {
        isGlimpseboxQueueConnected,
    }: {
        isGlimpseboxQueueConnected: boolean | "error"
    }
) {
    const tooltipText = () => {
        if (isGlimpseboxQueueConnected === "error") return "Glimpsebox status unknown, please contact Glimpse support."
        if (isGlimpseboxQueueConnected) return "Glimpsebox ready to process volumes"
        else return "No listener present, glimpsebox not ready to process volumes. Please power cycle the box and contact Glimpse support if it persists."
    }

    return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body1">
                {'Glimpsebox connection: '}
            </Typography>
            <Tooltip title={<Typography>{tooltipText()}</Typography>} placement="bottom">
                {isGlimpseboxQueueConnected ? (
                    <CloudDoneSharpIcon sx={{ color: overlayColorScheme.green, ml: 1 }} />
                ) : (
                    <CloudOffSharpIcon sx={{ color: overlayColorScheme.red, ml: 1 }} />
                )}
            </Tooltip>
        </Box>
    );
}
