import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { formatIsoDateTime } from '../../utils';
import { formatMetricName, InspectionChartData } from '../../utils/inspection/dashboard';

export default function DownloadChartData(
    { data }: { data: InspectionChartData[]; }
) {
    const handleDownloadCSV = () => {
        const headers = [
            "metric",
            "sn",
            "scan_id",
            "scan_processed_date",
            "mean",
            "median",
            "min",
            "max"
        ];
        const csvData = [headers.join(',')].concat(data.map((e) => {
            return [
                formatMetricName(e.metric_internal_name),
                e.sn, e.scan_id,
                formatIsoDateTime(e.scan_date),
                e.mean.toFixed(3),
                e.median.toFixed(5),
                e.min.toFixed(5),
                e.max.toFixed(5)
            ]
                .join(',');
        })).join('\n');

        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;

        function formatLocalDateTime(date: Date) {
            return date.toISOString().slice(0, 10) + '-' +
                date.toLocaleTimeString('en-GB', { hour12: false }).replace(/:/g, '');
        }

        const fileName = `glimpse_inspection_data_${formatLocalDateTime(new Date())}.csv`;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    return (
        <Tooltip placement='bottom' title={
            <Typography variant='body2'>
                Download the raw scan-level data.
            </Typography>
        }>
            <IconButton onClick={handleDownloadCSV}>
                <DownloadForOfflineOutlinedIcon />
            </IconButton>
        </Tooltip>
    );
}
