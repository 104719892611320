import { Box, Card } from '@mui/material';
import { AnnotationShapeList, MeasurementStatus, ScanList, SliceOrientation, SlicePositionsCyl } from '../../../types';
import { calculateTransformWrapperProps } from '../../../utils';
import BasicLoadingIndicator from '../../BasicLoadingIndicator';
import SliceImage from '../../SliceViewerPage/SliceImage';

export default function ScanImagePreviewer(
    {
        visibleImage,
        viewerPanelWidth,
        imageDimensions,
        orientation,
        voxelSize,
        annotationList
    }: {
        visibleImage: string,
        viewerPanelWidth: number,
        imageDimensions: { height: number, width: number },
        orientation: SliceOrientation
        voxelSize: number,
        annotationList: AnnotationShapeList[]
    }) {
    const dummyPositions = { axial: 0, radial: 0 } as SlicePositionsCyl
    const dummyScanData = {} as ScanList
    const dummySlicePosition = 0

    const viewerPanelHeight = viewerPanelWidth
    const imageViewerDimensions = { height: viewerPanelHeight, width: viewerPanelWidth }
    const imageMeasurementPadding = { horizontal: 0, vertical: 0 } // we don't allow manual measurements in here yet
    const transformWrapperProps = calculateTransformWrapperProps(imageViewerDimensions, imageDimensions, imageMeasurementPadding)


    if (visibleImage && imageDimensions && transformWrapperProps.initialScale)
        return <Card >
            <SliceImage
                isScanPreviewer={true}
                visibleImage={visibleImage}
                viewingPanelDimensions={imageViewerDimensions}
                sliceOrientation={orientation}
                voxelSize={voxelSize}
                imageDimensions={imageDimensions}
                imageMeasurementPadding={imageMeasurementPadding}
                transformWrapperProps={transformWrapperProps}
                annotationList={annotationList}
                // all dummy stuff not used in the preview data component
                slicePositions={dummyPositions}
                minSlicePositions={dummyPositions}
                scanData={dummyScanData}
                slicePosition={dummySlicePosition}
                allMeasurements={[]}
                updateManualMeasurements={() => { }}
                measurementStatus={MeasurementStatus.COMPLETE}
                setMeasurementStatus={() => { }}
            />
        </Card>
    return <Box ><BasicLoadingIndicator message='' /></Box>

}
