import { Box, Checkbox, Chip, FormHelperText, ListItemText } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';



export default function MultiBatchSelect(
    {
        requestsWithData,
        selectedRequests,
        setSelectedRequests
    }: {
        requestsWithData: {
            requestName: string;
            requestId: number;
        }[],
        selectedRequests: number[]
        setSelectedRequests: (selectedRequests: number[]) => void;
    }) {

    const handleSelectAllRequests = () => {
        if (requestsWithData.length !== selectedRequests.length) {
            setSelectedRequests(requestsWithData.map((e) => e.requestId));
        } else {
            setSelectedRequests([]);
        }
    };

    const handleChange = (event: SelectChangeEvent<typeof selectedRequests>) => {
        const { target: { value } } = event;
        setSelectedRequests(
            typeof value === 'number' ? [value] : value as
                number[]
        );
    };

    return <FormControl >
        <Select
            multiple
            value={selectedRequests}
            onChange={handleChange}
            renderValue={(selected) => (
                <Box sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 0.5,
                    maxHeight: 90,
                    overflowY: 'auto',
                }}>
                    {selected.sort((a, b) => a - b).map((e) => (
                        <Chip variant='outlined'
                            key={e}
                            label={requestsWithData.find(f =>
                                f.requestId === e)?.requestName || ""}
                            color='primary'
                            sx={{ borderRadius: 1 }}
                        />))}
                </Box>
            )}
            size='small'
            MenuProps={{ PaperProps: { sx: { maxHeight: 600, overflowY: 'auto' } } }}
        >
            <MenuItem dense >
                <Checkbox
                    sx={{ p: 0.5, m: 0 }}
                    checked={selectedRequests.length === requestsWithData.length}
                    onChange={handleSelectAllRequests} />
                <ListItemText primary={"All"} />
            </MenuItem>
            {requestsWithData.map((option) => (
                <MenuItem dense key={option.requestId} value={option.requestId}>
                    <Checkbox
                        sx={{ p: 0.5, m: 0 }}
                        checked={selectedRequests.indexOf(option.requestId) > -1}
                    />
                    <ListItemText primary={
                        option.requestName
                    } />
                </MenuItem>
            ))}
        </Select>
        <FormHelperText error={selectedRequests.length === 0}>{selectedRequests.length === 0 ? "Select at least one batch" : `Viewing ${selectedRequests.length} of ${requestsWithData.length} batches`}</FormHelperText>
    </FormControl>

}
