

import {
    AutomatedInspectionMetrics,
    AutomatedInspectionTableData,
    CalculatedStatistics,
    calculateStats,
    MetricDisplayNames,
    metricDisplayUnitSliceViewer,
    StatsDisplayNames
} from "."
import { RawCathodeTraceResults, RawElectrodeOverhangTraceResults, RawGeneralInspectionResults } from "../../types"
import { colorAnnotation, overlayColorScheme } from "../colorScheme"


const addCanWallThickness = (
    loadedMetrics: AutomatedInspectionTableData[],
    visibleInspectionNames: string[]
) => {

    const innerDiameter = loadedMetrics.find((e) => e.internal_name === AutomatedInspectionMetrics.CAN_INNER_DIAMETER_MEAN)?.result
    const outerDiameter = loadedMetrics.find((e) => e.internal_name === AutomatedInspectionMetrics.CAN_OUTER_DIAMETER_MEAN)?.result
    if (outerDiameter && innerDiameter) {
        const displayInformation = MetricDisplayNames.find((e) => e.internal_name === AutomatedInspectionMetrics.CAN_WALL_THICKNESS_MEAN)
        const canWallThickness = {
            internal_name: AutomatedInspectionMetrics.CAN_WALL_THICKNESS_MEAN,
            display_name: displayInformation?.display_name || "",
            result: (innerDiameter && outerDiameter) ? (outerDiameter - innerDiameter) / 2 : 0,
            display: visibleInspectionNames.includes(AutomatedInspectionMetrics.CAN_WALL_THICKNESS_MEAN),
            description: displayInformation?.description || "",
            display_units: metricDisplayUnitSliceViewer(AutomatedInspectionMetrics.CAN_WALL_THICKNESS_MEAN),
            color: colorAnnotation(AutomatedInspectionMetrics.CAN_WALL_THICKNESS_MEAN),
            group_id: groupingId(AutomatedInspectionMetrics.CAN_WALL_THICKNESS_MEAN).id,
            group_name: groupingId(AutomatedInspectionMetrics.CAN_WALL_THICKNESS_MEAN).group_display_name
        } as AutomatedInspectionTableData
        return canWallThickness
    }
    return null
}

const addMeanCrimpGrooveGap = (
    metricsAtSlice: AutomatedInspectionTableData[],
    visibleInspectionNames: string[]
) => {
    const min = metricsAtSlice.find((e) => e.internal_name === AutomatedInspectionMetrics.MIN_CRIMP_GROOVE_GAP)?.result
    const max = metricsAtSlice.find((e) => e.internal_name === AutomatedInspectionMetrics.MAX_CRIMP_GROOVE_GAP)?.result
    if (min && max) {
        const displayInformation = MetricDisplayNames.find((e) => e.internal_name === AutomatedInspectionMetrics.MEAN_CRIMP_GROOVE_GAP)
        const meanCrimpHeight = {
            internal_name: AutomatedInspectionMetrics.MEAN_CRIMP_GROOVE_GAP,
            display_name: displayInformation?.display_name || "",
            result: (min + max) / 2,
            display: visibleInspectionNames.includes(AutomatedInspectionMetrics.MEAN_CRIMP_GROOVE_GAP),
            description: displayInformation?.description || "",
            display_units: metricDisplayUnitSliceViewer(AutomatedInspectionMetrics.MEAN_CRIMP_GROOVE_GAP),
            color: colorAnnotation(AutomatedInspectionMetrics.MEAN_CRIMP_GROOVE_GAP),
            group_id: groupingId(AutomatedInspectionMetrics.MEAN_CRIMP_GROOVE_GAP).id,
            group_name: groupingId(AutomatedInspectionMetrics.MEAN_CRIMP_GROOVE_GAP).group_display_name
        } as AutomatedInspectionTableData
        return meanCrimpHeight
    }
    return null
}

const getAnodeAsymmetry = (resultsAtSlice: AutomatedInspectionTableData[], visibleInspectionNames: string[]) => {
    const top = resultsAtSlice.find((e) => e.internal_name === AutomatedInspectionMetrics.ANODE_OVERHANG_TOP && e.display_name === StatsDisplayNames.MEAN)?.result
    const bottom = resultsAtSlice.find((e) => e.internal_name === AutomatedInspectionMetrics.ANODE_OVERHANG_BOTTOM && e.display_name === StatsDisplayNames.MEAN)?.result
    const displayInformation = MetricDisplayNames.find((e) => e.internal_name === AutomatedInspectionMetrics.ANODE_OVERHANG_ASYMMETRY)

    if (top && bottom) {
        const mean = (top + bottom) / 2
        const asymmetryPercent = (top - mean) / mean * 100
        const asymmetryItem = {
            internal_name: AutomatedInspectionMetrics.ANODE_OVERHANG_ASYMMETRY,
            display_name: displayInformation?.display_name || "",
            result: asymmetryPercent,
            display: visibleInspectionNames.includes(AutomatedInspectionMetrics.ANODE_OVERHANG_ALL),
            description: displayInformation?.description || "",
            display_units: metricDisplayUnitSliceViewer(AutomatedInspectionMetrics.ANODE_OVERHANG_ASYMMETRY),
            color: colorAnnotation(AutomatedInspectionMetrics.ANODE_OVERHANG_ASYMMETRY),
            group_id: groupingId(AutomatedInspectionMetrics.ANODE_OVERHANG_ASYMMETRY).id,
            group_name: groupingId(AutomatedInspectionMetrics.ANODE_OVERHANG_ASYMMETRY).group_display_name
        } as AutomatedInspectionTableData
        return asymmetryItem
    }
    return null
}

const getAnodeAndCathodeMetrics = (
    visibleInspectionNames: string[],
    rawCathodeTraceResults: RawCathodeTraceResults[],
    rawElectrodeOverhangTraceResults: RawElectrodeOverhangTraceResults[],
    slice_id: number | undefined
) => {
    let resultsAtSlice: AutomatedInspectionTableData[] = []
    const cathodeValues = rawCathodeTraceResults.filter((e) => e.slice_id === slice_id)
    const electrodeValues = rawElectrodeOverhangTraceResults.filter((e) => e.slice_id === slice_id)
    let metricsToProcess = []

    const getMetricBase = (
        metric_internal_name: AutomatedInspectionMetrics
    ) => {
        return {
            internal_name: metric_internal_name,
            description: MetricDisplayNames.find((e) => e.internal_name === metric_internal_name)?.description || "",
            display_units: metricDisplayUnitSliceViewer(metric_internal_name),
            group_id: groupingId(metric_internal_name).id,
            group_name: groupingId(metric_internal_name).group_display_name
        };
    };

    type MetricBase = ReturnType<typeof getMetricBase>

    const addMetricResults = (metricBase: MetricBase, metricResults: CalculatedStatistics) => ([
        {
            ...metricBase,
            internal_name: metricBase.internal_name,
            display: visibleInspectionNames.includes(metricBase.internal_name),
            statistic: StatsDisplayNames.MIN,
            result: metricResults.min,
            display_name: StatsDisplayNames.MIN,
            description: metricBase.description + " - " + StatsDisplayNames.MIN + " of slice",
            color: `${overlayColorScheme.orange}98`
        },
        {
            ...metricBase,
            internal_name: metricBase.internal_name,
            display: visibleInspectionNames.includes(metricBase.internal_name),
            statistic: StatsDisplayNames.MAX,
            result: metricResults.max,
            display_name: StatsDisplayNames.MAX,
            description: metricBase.description + " - " + StatsDisplayNames.MAX + " of slice",
            color: `${overlayColorScheme.green}98`
        },
        {
            ...metricBase,
            internal_name: metricBase.internal_name,
            display: visibleInspectionNames.includes(metricBase.internal_name),
            statistic: StatsDisplayNames.MEAN,
            result: metricResults.mean,
            display_name: StatsDisplayNames.MEAN,
            description: metricBase.description + " - " + StatsDisplayNames.MEAN + " of slice",
            color: `${overlayColorScheme.blue}98`
        },
        {
            ...metricBase,
            internal_name: metricBase.internal_name,
            display: visibleInspectionNames.includes(metricBase.internal_name),
            statistic: StatsDisplayNames.STD_DEV,
            result: metricResults.stdDev,
            display_name: StatsDisplayNames.STD_DEV,
            description: metricBase.description + " - " + StatsDisplayNames.STD_DEV + " of slice",
            color: `${overlayColorScheme.blue}98`
        }
    ]);

    const processMetric = (
        metric: AutomatedInspectionMetrics | undefined,
        values: RawElectrodeOverhangTraceResults[] | RawCathodeTraceResults[],
        traceKey: string,
    ) => {
        if (metric) {
            try {
                const metricBase = getMetricBase(metric);
                const metricResults = calculateStats(values.map((e: any) => e[traceKey])); // TODO: any type
                const metricsToAdd = addMetricResults(metricBase, metricResults);
                resultsAtSlice.push(...metricsToAdd);
            } catch (error) {
                console.error(error);
            }
        }
    };


    if (cathodeValues.length > 0) metricsToProcess.push(...[
        { metric: AutomatedInspectionMetrics.CATHODE_WIDTH, values: cathodeValues, key: 'trace_length' },
        { metric: AutomatedInspectionMetrics.CATHODE_POSITION_TOP, values: cathodeValues, key: 'top_position' },
        { metric: AutomatedInspectionMetrics.CATHODE_POSITION_BOTTOM, values: cathodeValues, key: 'bottom_position' },
    ])
    if (electrodeValues.length > 0) metricsToProcess.push(...[
        { metric: AutomatedInspectionMetrics.ANODE_OVERHANG_TOP, values: electrodeValues.filter((e) => e.location === "top"), key: 'trace_length' },
        { metric: AutomatedInspectionMetrics.ANODE_OVERHANG_BOTTOM, values: electrodeValues.filter((e) => e.location === "bottom"), key: 'trace_length' },
        { metric: AutomatedInspectionMetrics.ANODE_OVERHANG_ALL, values: electrodeValues, key: 'trace_length' }
    ]);

    metricsToProcess.forEach(({ metric, values, key }) => {
        processMetric(metric, values, key);
    });

    const asymmetry = getAnodeAsymmetry(resultsAtSlice, visibleInspectionNames)
    if (asymmetry) resultsAtSlice.push(asymmetry)

    const allAnodesMin = resultsAtSlice.find(
        (e) => e.internal_name === AutomatedInspectionMetrics.ANODE_OVERHANG_ALL &&
            e.display_name === StatsDisplayNames.MIN)
    const anodeOverhangViolationThreshold = 0
    if (allAnodesMin) {
        resultsAtSlice.push({
            internal_name: AutomatedInspectionMetrics.INSUFFICIENT_ANODE_OVERHANG,
            result: allAnodesMin.result <= anodeOverhangViolationThreshold ? 1 : 0,
            display: visibleInspectionNames.includes(AutomatedInspectionMetrics.ANODE_OVERHANG_ALL),
            description: MetricDisplayNames.find((e) => e.internal_name === AutomatedInspectionMetrics.INSUFFICIENT_ANODE_OVERHANG)?.description || "",
            display_name: MetricDisplayNames.find((e) => e.internal_name === AutomatedInspectionMetrics.INSUFFICIENT_ANODE_OVERHANG)?.display_name || "",
            display_units: "",
            color: allAnodesMin.color,
            group_id: allAnodesMin.group_id,
            group_name: allAnodesMin.group_name
        })
    }

    return resultsAtSlice;
}



export const getAutomatedInspectionTableData = (
    slice_id: number | undefined,
    visibleInspectionNames: string[],
    allRawAutomatedInspectionResults: {
        general: RawGeneralInspectionResults[],
        cathode: RawCathodeTraceResults[],
        electrode: RawElectrodeOverhangTraceResults[]
    }
) => {
    // first, most metrics are 1:1 shape to value:
    const metricsAtSlice = allRawAutomatedInspectionResults.general
        .filter((e) => e.slice_id === slice_id)
        .map((e) => {
            const metricDisplay = MetricDisplayNames.find((f) => f.internal_name === e.metric_internal_name)
            return {
                internal_name: e.metric_internal_name || "",
                result: e.value,
                display: visibleInspectionNames.includes(e.metric_internal_name),
                description: metricDisplay?.description || "",
                display_name: metricDisplay?.display_name || "",
                display_units: metricDisplayUnitSliceViewer(e.metric_internal_name),
                color: colorAnnotation(e.metric_internal_name as AutomatedInspectionMetrics),
                group_id: groupingId(e.metric_internal_name).id, // Dropping grouping for now
                group_name: groupingId(e.metric_internal_name).group_display_name
            } as AutomatedInspectionTableData
        })

    // Special cases to be calculated: can wall, crimp groove gap,
    const canWallThickness = addCanWallThickness(metricsAtSlice, visibleInspectionNames)
    if (canWallThickness) metricsAtSlice.push(canWallThickness)


    // anodes and cathodes are a special case to be calculated:
    const anodesAndCathodes = getAnodeAndCathodeMetrics(
        visibleInspectionNames,
        allRawAutomatedInspectionResults.cathode,
        allRawAutomatedInspectionResults.electrode,
        slice_id)
    if (anodesAndCathodes) metricsAtSlice.push(...anodesAndCathodes)

    const meanCrimpGrooveGap = addMeanCrimpGrooveGap(metricsAtSlice, visibleInspectionNames)
    if (meanCrimpGrooveGap) metricsAtSlice.push(meanCrimpGrooveGap)

    return metricsAtSlice as AutomatedInspectionTableData[]
}



export const groupingId = (metricName: string) => {
    enum MetricGroupNames {
        // This is for the grouping of metrics in the Slice view tables (tables under the overlays)
        OVERHANG_ALL = "Electrode overhang - all",
        OVERHANG_TOP = "Electrode overhang - top of cell",
        OVERHANG_BOTTOM = "Electrode overhang - bottom of cell",
        CATHODE_WIDTH = "Cathode width",
        CATHODE_POSITION_TOP = "Cathode position - top of cell",
        CATHODE_POSITION_BOTTOM = "Cathode position - bottom of cell",
        CORE = "Jellyroll core",
        CAN = "Can",
        CRIMP = "Crimp",
        OOS = "OOS angles"
    }
    switch (metricName) {
        // Axial:
        case AutomatedInspectionMetrics.ANODE_OVERHANG_TOP:
            return { id: 1, group_display_name: MetricGroupNames.OVERHANG_TOP };
        case AutomatedInspectionMetrics.ANODE_OVERHANG_BOTTOM:
            return { id: 2, group_display_name: MetricGroupNames.OVERHANG_BOTTOM };
        case AutomatedInspectionMetrics.CATHODE_WIDTH:
            return { id: 3, group_display_name: MetricGroupNames.CATHODE_WIDTH };
        case AutomatedInspectionMetrics.CATHODE_POSITION_TOP:
            return { id: 4, group_display_name: MetricGroupNames.CATHODE_POSITION_TOP };
        case AutomatedInspectionMetrics.CATHODE_POSITION_BOTTOM:
            return { id: 5, group_display_name: MetricGroupNames.CATHODE_POSITION_BOTTOM };
        case AutomatedInspectionMetrics.ANODE_OVERHANG_ASYMMETRY:
        case AutomatedInspectionMetrics.ANODE_OVERHANG_ALL:
        case AutomatedInspectionMetrics.INSUFFICIENT_ANODE_OVERHANG:
            return { id: 6, group_display_name: MetricGroupNames.OVERHANG_ALL };
        case AutomatedInspectionMetrics.LEFT_CRIMP_HEIGHT:
        case AutomatedInspectionMetrics.RIGHT_CRIMP_HEIGHT:
        case AutomatedInspectionMetrics.MAX_CRIMP_GROOVE_GAP:
        case AutomatedInspectionMetrics.MIN_CRIMP_GROOVE_GAP:
        case AutomatedInspectionMetrics.MEAN_CRIMP_GROOVE_GAP:
            return { id: 9, group_display_name: MetricGroupNames.CRIMP };
        // Radial:
        case AutomatedInspectionMetrics.CORE_AREA:
        case AutomatedInspectionMetrics.CORE_CIRCULARITY_DMIN_DMAX:
        case AutomatedInspectionMetrics.CORE_CIRCULARITY_MCC:
        case AutomatedInspectionMetrics.ELECTRODE_BUCKLING_DETECTED:
        case AutomatedInspectionMetrics.CORE_CONCENTRICITY:
        case AutomatedInspectionMetrics.CORE_EFFECTIVE_DIAMETER:
        case AutomatedInspectionMetrics.ANODE_TAB_CURVATURE:
        case AutomatedInspectionMetrics.CORE_ANODE_GAP_WIDTH:
            return { id: 7, group_display_name: MetricGroupNames.CORE };
        case AutomatedInspectionMetrics.CAN_INNER_DIAMETER_MEAN:
        case AutomatedInspectionMetrics.CAN_OUTER_DIAMETER_MEAN:
        case AutomatedInspectionMetrics.CAN_WALL_THICKNESS_MEAN:
        case AutomatedInspectionMetrics.CAN_CIRCULARITY_DMIN_DMAX:
        case AutomatedInspectionMetrics.CAN_MAX_DENTING:
            return { id: 8, group_display_name: MetricGroupNames.CAN };
        case AutomatedInspectionMetrics.CATHODE_TO_END_ANODE_TAB:
        case AutomatedInspectionMetrics.MIDDLE_CATHODE_TAB_TO_MIDDLE_ANODE_TAB:
        case AutomatedInspectionMetrics.CATHODE_START_TO_MIDDLE_CATHODE_TAB:
            return { id: 9, group_display_name: MetricGroupNames.OOS };
        default:
            return { id: 0, group_display_name: "" };
    }
}
