import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { XAxisOptions } from '../../utils/inspection/dashboard';


export default function XAxisToggle(
    { xAxisType, setXAxisType }: {
        xAxisType: XAxisOptions;
        setXAxisType: React.Dispatch<React.SetStateAction<XAxisOptions>>;
    }) {
    const xAxisOptions = [
        { value: XAxisOptions.SCAN_ID, label: 'Serial Number', tooltip: 'View data by serial number in the order that they were scanned' },
        { value: XAxisOptions.DATE, label: 'Scan Date', tooltip: 'View by the date and time the scan processing was completed' },
        { value: XAxisOptions.BATCH, label: 'Batch', tooltip: 'Aggregate data to the batch level' },
        { value: XAxisOptions.CROSS_COMPARE, label: 'Cross Compare', tooltip: 'Plot metrics against each other (select between two and five metrics)' },
    ];
    return (
        <ToggleButtonGroup
            value={xAxisType}
            fullWidth
            size="small"
            exclusive
            onChange={(_, newValue) => {
                if (newValue !== null) {
                    setXAxisType(newValue);
                }
            }}        >
            {xAxisOptions.map(({ value, label, tooltip }) => (
                <Tooltip key={value} title={tooltip} placement="bottom">
                    <ToggleButton value={value} selected={xAxisType === value} sx={{ lineHeight: 1.2 }}>
                        {label}
                    </ToggleButton>
                </Tooltip>
            ))}
        </ToggleButtonGroup>
    );
}
