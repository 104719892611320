import { TermsOfServiceVersions } from "../pages/terms_of_service/termsLibrary";
import { PageOptions, ScanAxes, ScanMetadata, SliceOrientation, SliceRow } from "../types";
import { ApiEndpoints, ParamsType } from "./apiUtils";

export const appTopNavBarHeight = 64;

// Open a scan in a new tab with the id and if is demo mode
export const newTabLink = (scanId: number, isDemoMode: boolean) => {
    const baseUrl = process.env.REACT_APP_API_BASEURL as string
    const urlParams = "/?page=slices&public=" + isDemoMode + "&selectedScanId=" + scanId.toString()
    if (process.env.REACT_APP_API_BASEURL === "http://localhost:5000") {
        return "http://localhost:3000" + urlParams
    }
    return baseUrl + urlParams
}


export const openTermsPageNewTab = () => {
    const baseUrl = process.env.REACT_APP_API_BASEURL as string
    const version = TermsOfServiceVersions.demo_latest
    if (process.env.REACT_APP_API_BASEURL === "http://localhost:5000") {
        return "http://localhost:3000/?page=" + PageOptions.TERMS + "&tos=" + version
    }
    return baseUrl + "/?page=tos&tos=" + version
}

export const minMaxSliderValues = (sliceList: SliceRow[]) => {
    return {
        max: Math.max(...sliceList
            .map((slice: SliceRow) => slice.position)),
        min: Math.min(...sliceList
            .map((slice: SliceRow) => slice.position)),
    }
}

export const middleSlicePosition = (orientation: SliceOrientation, sliceList: SliceRow[]) => {
    const sublist = sliceList.filter((slice: SliceRow) => slice.orientation === orientation)
    const minMax = minMaxSliderValues(sublist)
    const middle = Math.floor((minMax.max + minMax.min) / 2)
    return sublist.reduce((prev, curr) => Math.abs(curr.position - middle) < Math.abs(prev.position - middle) ? curr : prev).position
}

/**
 * Get the valid slice position from the URL or the middle slice position.
 */
export const getValidSlicePosition = (sliceList: SliceRow[], orientation: SliceOrientation, urlValue?: string) => {
    const positions = sliceList
        .filter((slice: SliceRow) => slice.orientation === orientation)
        .map((slice: SliceRow) => slice.position);
    return urlValue && positions.includes(Number(urlValue)) ? Number(urlValue) : middleSlicePosition(orientation, sliceList);
};

export const formatIsoDate = (date: Date) => {
    return new Date(date).toLocaleString().split(',')[0]
}


export const formatIsoDateTime = (date: Date, useLocalTime?: boolean) => {
    if (useLocalTime) {
        // Convert to local time string, in the timezone of the browser
        const localDateTime = new Date(date).toLocaleString('en-CA', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
        });

        // Replace date and time separators to match ISO format
        return localDateTime.replace(',', '').replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$2-$1');
    } else {
        // Convert to UTC time string in ISO format, and we strip the timezome data so UTC remains.
        const isoDate = new Date(date).toISOString().split('T')[0];
        const isoTime = new Date(date).toISOString().split('T')[1].split('.')[0];
        return `${isoDate} ${isoTime}`;
    }
}

export const getLocalTimezoneAcronym = () => {
    const date = new Date();
    const formatter = new Intl.DateTimeFormat('en-US', {
        timeZoneName: 'short'
    });

    const formatted = formatter.formatToParts(date);
    const timezonePart = formatted.find(part => part.type === 'timeZoneName');

    return timezonePart ? timezonePart.value : undefined;
}


export const calculateDateDifference = (date1: Date, date2: Date): number => {
    const time1 = new Date(date1).getTime();
    const time2 = new Date(date2).getTime();
    const diff = Math.abs(time1 - time2);
    const diffMinutes = diff / (1000 * 60);
    return parseFloat(diffMinutes.toFixed(2));
};


/**
 * Calculate the padding used in the PinchZoom component for viewing slices.
 */
export const calculatePadding = (
    paddingPercent: number,
    rawImageDimensions: { height: number, width: number },
) => {
    let imageMeasurementPadding = { horizontal: rawImageDimensions.width * paddingPercent, vertical: rawImageDimensions.height * paddingPercent };
    if (rawImageDimensions.height / rawImageDimensions.width >= 1) {
        imageMeasurementPadding.horizontal = (rawImageDimensions.height / 2 * (1 + paddingPercent)) - (rawImageDimensions.width / 2)
    }
    if (rawImageDimensions.height / rawImageDimensions.width < 1) {
        imageMeasurementPadding.vertical = (rawImageDimensions.width / 2 * (1 + paddingPercent)) - (rawImageDimensions.height / 2)
    }
    return imageMeasurementPadding
}


/**
 * Calculate the initial scale and zoom required for the image to load nicely in the PinchZoom component for viewing slices.
 */
export const calculateTransformWrapperProps = (
    imageViewerDimensions: { height: number, width: number },
    rawImageDimensions: { height: number, width: number },
    imageMeasurementPadding: { horizontal: number, vertical: number }
) => {
    const defaultScale = Math.min(imageViewerDimensions.height / rawImageDimensions.height,
        imageViewerDimensions.width / rawImageDimensions.width) / 1.05
    const initialPosition = {
        x: imageViewerDimensions.width / 2 - rawImageDimensions.width * defaultScale / 2 - imageMeasurementPadding.horizontal * defaultScale,
        y: imageViewerDimensions.height / 2 - rawImageDimensions.height * defaultScale / 2 - imageMeasurementPadding.vertical * defaultScale
    }
    return {
        initialScale: defaultScale,
        minScale: defaultScale / 1.3,
        initialPositionX: Math.round(initialPosition.x * 1),
        initialPositionY: Math.round(initialPosition.y * 1)
    };
}

export async function getImageDimensions(imageUrl: string): Promise<{ height: number; width: number }> {
    return new Promise((resolve) => {
        const img = new Image();
        img.src = imageUrl;
        img.onload = () => {
            resolve({ height: img.height, width: img.width });
        };
    });
}

export async function getFirstThumbnailImage(
    slice_id: number | null,
    isDemoMode: boolean,
    fetchData: (endpoint: ApiEndpoints, params: any, config?: any) => Promise<any>
): Promise<{ imageUrl: string | null; dimensions: { width: number; height: number } | null }> {
    if (slice_id === null) return { imageUrl: null, dimensions: null };

    let params: any = isDemoMode ? { is_demo: true } : {};
    params = { ...params, slice_id: slice_id };

    try {
        const response = await fetchData(ApiEndpoints.SLICE_IMAGE_PNG, params, { responseType: "arraybuffer" });
        const imageResponse: ArrayBuffer = response.data;
        const contentType = response.headers["content-type"];
        let imageType = contentType === "image/avif" ? "image/avif" : "image/png";

        const imageBlob = new Blob([imageResponse], { type: imageType });
        const imageUrl = URL.createObjectURL(imageBlob);

        const dimensions = await getImageDimensions(imageUrl);

        return { imageUrl, dimensions };
    } catch (error) {
        console.error("Error fetching thumbnail image:", error);
        return { imageUrl: null, dimensions: null };
    }
}


export const getSliceList = async (
    scanId: number,
    isDemoMode: boolean,
    fetchData: (endpoint: ApiEndpoints, params: any, config?: any) => Promise<any>
) => {
    const params = { scan_id: scanId } as ParamsType
    const endpoint = isDemoMode ? ApiEndpoints.DEMO_SLICE_LIST : ApiEndpoints.SLICE_LIST
    const responseSliceList = await fetchData(endpoint, params);
    try {
        if (responseSliceList.data.length > 0) {
            const sliceListRaw = responseSliceList.data as SliceRow[]
            const sliceList = sliceListRaw.map((slice: SliceRow) => {
                // A "decimal" from marshmallow is a string, so we convert to a float here
                return { ...slice, position: Number(parseFloat(slice.position.toString()).toFixed(2)) }
            })
            return { sliceList: sliceList, axes: responseSliceList.data[0]['axes'] as ScanAxes || null }
        }
        else {
            throw new Error("No slices found for this scan")
        }
    }
    catch (error: any) {
        console.error(error)
    }
}


export async function getInspectionResults(isDemoMode: boolean, scan_id: number, orientation: SliceOrientation,
    fetchData: (endpoint: ApiEndpoints, params: any, config?: any) => Promise<any>,
) {
    let params = { scan_id: scan_id, orientation: orientation } as ParamsType
    if (isDemoMode) params = { ...params, is_demo: true }
    switch (orientation) {
        case SliceOrientation.RADIAL:
            try {
                const responseInspectionResults = await fetchData(ApiEndpoints.INSPECTION_RESULTS_GENERAL, params)
                return {
                    general: responseInspectionResults.data,
                    cathode: [],
                    electrode: []
                }
            }
            catch (error: any) { console.error(error) }
            break;
        case SliceOrientation.AXIAL:
            try {
                const responseInspectionResults = await fetchData(ApiEndpoints.INSPECTION_RESULTS_GENERAL, params)
                const responseCathode = await fetchData(ApiEndpoints.INSPECTION_RESULTS_AXIAL_CATHODE_TRACE, params)
                const responseElectrode = await fetchData(ApiEndpoints.INSPECTION_RESULTS_AXIAL_ELECTRODE_OVERHANG_TRACE, params)
                return {
                    general: responseInspectionResults.data,
                    cathode: responseCathode.data,
                    electrode: responseElectrode.data
                }
            }
            catch (error: any) { console.error(error) }
            break;
        case SliceOrientation.XY:
        case SliceOrientation.XZ:
        case SliceOrientation.YZ:
        default:
            return {
                general: [],
                cathode: [],
                electrode: []
            }
    }
}

/**
 * For tables and user display
 */
export function formatMetadata(metadata: ScanMetadata[]): string {
    if (metadata === null) { return ""; }
    try {
        return metadata.map(item => `${item.field}: ${item.value}`).join(', ');
    } catch (error) {
        console.error("Error parsing metadata:", error);
        return "";
    }
}
