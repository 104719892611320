import { Close } from "@mui/icons-material";
import { Box, IconButton, InputAdornment, Stack, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CustomDatePickerWrapper = styled("div")(({ theme }) => ({
    "& .react-datepicker": {
        backgroundColor: theme.palette.background.paper, // MUI background color
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[3],
        padding: theme.spacing(1),
        fontFamily: theme.typography.fontFamily,
    },
    "& .react-datepicker__header": {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.primary.contrastText,
        borderBottom: "none",
    },
    "& .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header":
    {
        color: theme.palette.text.primary,
    },
    "& .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name":
    {
        color: theme.palette.text.secondary,
    },
    "& .react-datepicker__day--selected": {
        backgroundColor: theme.palette.secondary.main, // Use MUI secondary color for selected date
        color: theme.palette.secondary.contrastText,
        borderRadius: "50%",
    },
    "& .react-datepicker__day:hover": {
        backgroundColor: theme.palette.action.hover, // Use MUI hover color
    },
}));

export default function DateRangePicker({
    dateFilters,
    setDateFilters
}: {
    dateFilters: { start: Date | null, end: Date | null },
    setDateFilters: React.Dispatch<React.SetStateAction<{ start: Date | null, end: Date | null }>>
}) {

    const handleDateChange = (date: Date | null, isStart: boolean) => {
        if (isStart) {
            setDateFilters({ start: date, end: dateFilters.end });
        } else {
            setDateFilters({ start: dateFilters.start, end: date });
        }
    }

    return (
        <Box>
            <CustomDatePickerWrapper>
                <Stack direction="row" spacing={1}>
                    <DatePicker
                        selected={dateFilters.start}
                        onChange={(date) => handleDateChange(date, true)}
                        selectsStart
                        startDate={dateFilters.start}
                        endDate={dateFilters.end}
                        popperPlacement="bottom-start"
                        customInput={
                            <TextField
                                value={dateFilters.start}
                                size="small"
                                label="Scan date (start)"
                                sx={{
                                    fontSize: "13px",
                                    backgroundColor: "background.paper",
                                    borderRadius: 1,
                                }}
                                InputProps={{
                                    endAdornment: dateFilters.start && (
                                        <InputAdornment position="end">
                                            <IconButton
                                                size="small"
                                                onClick={() => handleDateChange(null, true)}
                                            >
                                                <Close fontSize="small" />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        }
                    />
                    <DatePicker
                        selected={dateFilters.end}
                        onChange={(date) => handleDateChange(date, false)}
                        selectsEnd
                        startDate={dateFilters.start}
                        endDate={dateFilters.end}
                        popperPlacement="bottom-start"
                        customInput={
                            <TextField
                                value={dateFilters.end}
                                size="small"
                                label="Scan date (end)"
                                sx={{
                                    fontSize: "13px",
                                    backgroundColor: "background.paper",
                                    borderRadius: 1,
                                }}
                                InputProps={{
                                    endAdornment: dateFilters.end && (
                                        <InputAdornment position="end">
                                            <IconButton
                                                size="small"
                                                onClick={() => handleDateChange(null, false)}
                                            >
                                                <Close fontSize="small" />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        }
                        minDate={dateFilters.start === null ? undefined : dateFilters.start}
                    />
                </Stack>
            </CustomDatePickerWrapper>
        </Box>
    );
}
