import { Checkbox, FormHelperText, ListSubheader } from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { AutomatedInspectionMetrics } from '../../utils/inspection';
import { displayNamer } from '../../utils/inspection/dashboard';


export default function MultiMetricSelect(
    {
        selectedMetrics,
        availableMetricOptions,
        setSelectedMetrics,
    }: {
        selectedMetrics: AutomatedInspectionMetrics[]
        availableMetricOptions: AutomatedInspectionMetrics[]
        setSelectedMetrics: (selectedMetrics: AutomatedInspectionMetrics[]) => void
    }) {


    const handleChange = (event: SelectChangeEvent<typeof selectedMetrics>) => {
        const { target: { value } } = event;
        if (value.length > 5) return
        setSelectedMetrics(
            // On autofill we get a stringified value.
            typeof value === 'string' ?
                value.split(',') as AutomatedInspectionMetrics[] :
                value
        );
    };

    // Categorize metrics
    const CATEGORY_MAP: Record<string, AutomatedInspectionMetrics[]> = {
        "Anode": [
            AutomatedInspectionMetrics.ANODE_OVERHANG_ALL,
            AutomatedInspectionMetrics.ANODE_OVERHANG_BOTTOM,
            AutomatedInspectionMetrics.ANODE_OVERHANG_TOP,
            AutomatedInspectionMetrics.ANODE_OVERHANG_ASYMMETRY,
        ],
        "Can": [
            AutomatedInspectionMetrics.CAN_MAX_DENTING,
            AutomatedInspectionMetrics.CAN_CIRCULARITY_DMIN_DMAX,
            AutomatedInspectionMetrics.CAN_INNER_DIAMETER_MEAN,
            AutomatedInspectionMetrics.CAN_OUTER_DIAMETER_MEAN,
            AutomatedInspectionMetrics.CAN_WALL_THICKNESS_MEAN,
        ],
        "Core": [
            AutomatedInspectionMetrics.CORE_AREA,
            AutomatedInspectionMetrics.CORE_CONCENTRICITY,
            AutomatedInspectionMetrics.CORE_EFFECTIVE_DIAMETER,
            AutomatedInspectionMetrics.CORE_CIRCULARITY_MCC,
            AutomatedInspectionMetrics.CORE_CIRCULARITY_DMIN_DMAX,
        ],
        "Crimp": [
            AutomatedInspectionMetrics.CRIMP_HEIGHT,
            AutomatedInspectionMetrics.CRIMP_GROOVE_GAP,
        ],
    };


    const categorizedMetrics: Record<string, AutomatedInspectionMetrics[]> = {};

    for (const [category, metrics] of Object.entries(CATEGORY_MAP)) {
        const filteredMetrics = metrics.filter(m => availableMetricOptions.includes(m));
        if (filteredMetrics.length > 0) {
            categorizedMetrics[category] = filteredMetrics;
        }
    }

    const allCategorizedMetrics = Object.values(CATEGORY_MAP).flat();
    const otherMetrics = availableMetricOptions.filter(m => !allCategorizedMetrics.includes(m));

    if (otherMetrics.length > 0) {
        categorizedMetrics["Other"] = otherMetrics;
    }


    return <FormControl sx={{ mt: 1 }}>
        <Select
            multiple
            value={selectedMetrics}
            onChange={handleChange}
            input={<OutlinedInput />}
            renderValue={(selected) => (
                <Box sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 0.5,
                    maxHeight: 90,
                    overflowY: 'auto',
                }}>
                    {selected.map((e) => (<Chip variant='outlined' key={e} label={displayNamer(e)} color='primary' sx={{ borderRadius: 1 }} />))}
                </Box>
            )}
            size='small'
            MenuProps={{
                PaperProps: {
                    sx: {
                        maxHeight: 500,
                        overflowY: 'auto',
                        border: '0.1pt solid',
                    }
                }
            }}
        >
            {Object.entries(categorizedMetrics).map(([category, metrics]) => (
                metrics.length > 0 && [
                    <ListSubheader
                        sx={{
                            fontWeight: 'bold',
                            color: 'text.primary',
                        }}
                        key={category}
                    >
                        {category}:
                    </ListSubheader>,
                    ...metrics.map((e) => (
                        <MenuItem dense key={e} value={e}>
                            <Checkbox sx={{ p: 0.5, m: 0 }} checked={selectedMetrics.includes(e)} /> {displayNamer(e)}
                        </MenuItem>
                    ))
                ]
            ))}
        </Select>
        <FormHelperText
            error={selectedMetrics.length === 0}
        >{selectedMetrics.length === 0 ? "Select at least one metric" : "Select up to five metrics"}</FormHelperText>

    </FormControl>
}
